import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MissionOrgStatus } from '../mission-org';
import { MissionOrgService } from '../mission-org.service';
import { BaseEditComponent } from '../../components/base-edit-component';
import { MissionOrgLevel, MissionOrgYearSummary } from '../mission-org-year-summary';
import { Year } from '../../people/training-requirement/year';
import { RenderTemplateBottomSheetComponent } from '../../components/render-template-bottom-sheet/render-template-bottom-sheet.component';
var MissionOrgComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MissionOrgComponent, _super);
    function MissionOrgComponent(missionOrgService, route, router, snackBar, bottomSheet, dialog) {
        var _this = _super.call(this, dialog) || this;
        _this.missionOrgService = missionOrgService;
        _this.route = route;
        _this.router = router;
        _this.snackBar = snackBar;
        _this.bottomSheet = bottomSheet;
        _this.schoolYears = Year.years();
        _this.statusList = Object.keys(MissionOrgStatus);
        _this.levelList = Object.keys(MissionOrgLevel);
        _this.isNew = false;
        _this.createNewYear = function () {
            var yearSummary = new MissionOrgYearSummary();
            yearSummary.missionOrgId = _this.missionOrg.id;
            return yearSummary;
        };
        _this.deleteYear = function (yearSummary) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.missionOrgService.deleteYear(yearSummary.id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        }); };
        _this.saveYear = function (yearSummary) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = [{}, yearSummary];
                        return [4 /*yield*/, this.missionOrgService.saveYear(yearSummary)];
                    case 1: return [2 /*return*/, tslib_1.__assign.apply(void 0, _a.concat([_b.sent()]))];
                }
            });
        }); };
        return _this;
    }
    MissionOrgComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (value) {
            _this.missionOrg = value.missionOrg;
            _this.people = value.people;
            _this.isNew = !_this.missionOrg.id;
        });
    };
    MissionOrgComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.missionOrgService.save(this.missionOrg)];
                    case 1:
                        _a.sent();
                        this.router.navigate(['/mission-org/list']);
                        this.snackBar.open(this.missionOrg.name + " " + (this.isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    MissionOrgComponent.prototype.delete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete Sending Org " + this.missionOrg.name + "?", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.missionOrgService.delete(this.missionOrg.id)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['/mission-org/list']);
                        this.snackBar.open(this.missionOrg.name + " Deleted", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    MissionOrgComponent.prototype.showStaff = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isNew) return [3 /*break*/, 1];
                        this.peopleInOrg = [];
                        return [3 /*break*/, 3];
                    case 1:
                        if (!!this.peopleInOrg) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.missionOrgService.listPeople(this.missionOrg.id).toPromise()];
                    case 2:
                        _a.peopleInOrg = _b.sent();
                        _b.label = 3;
                    case 3:
                        RenderTemplateBottomSheetComponent.Open(this.bottomSheet, 'staff');
                        return [2 /*return*/];
                }
            });
        });
    };
    return MissionOrgComponent;
}(BaseEditComponent));
export { MissionOrgComponent };
