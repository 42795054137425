import { combineLatest } from 'rxjs';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrgTree } from './org-node';
import { NestedTreeControl } from '@angular/cdk/tree';
import { UrlBindingService } from '../services/url-binding.service';
var OrgTreeComponent = /** @class */ (function () {
    function OrgTreeComponent(route, urlBinding) {
        var _this = this;
        this.route = route;
        this.urlBinding = urlBinding;
        this.isOrgNode = function (i, node) {
            return node.type == 'org';
        };
        this.isJobNode = function (i, node) {
            return node.type == 'job';
        };
        this.isRoleNode = function (i, node) {
            return node.type == 'role';
        };
        this.urlBinding.addParam('allRoles', false);
        this.urlBinding.addParam('allJobs', false);
        this.urlBinding.addParam('show', ['staff']);
        this.urlBinding.addParam('expanded', []);
        this.treeControl = new NestedTreeControl(function (dataNode) { return dataNode.observableChildren; });
        this.treeControl.getLevel = function (dataNode) { return dataNode.level; };
        combineLatest(this.route.params, this.route.data).subscribe(function (_a) {
            var params = _a[0], data = _a[1];
            _this.data = data.treeData;
            _this.rootId = params.rootId;
            _this.urlBinding.loadFromParams();
            _this.buildList();
        });
    }
    OrgTreeComponent.prototype.buildList = function () {
        this.orgTree = new OrgTree(this.data, this.urlBinding, this.rootId);
        this.nodes = this.orgTree.nodes;
        var expanded = this.urlBinding.values.expanded;
        if (expanded.length == 0) {
            for (var _i = 0, _a = this.nodes; _i < _a.length; _i++) {
                var node = _a[_i];
                this.treeControl.expand(node);
                this.nodeToggled(node);
            }
        }
        else {
            for (var _b = 0, expanded_1 = expanded; _b < expanded_1.length; _b++) {
                var nodeIdToExpand = expanded_1[_b];
                var node = this.findNode(this.nodes, nodeIdToExpand);
                if (node)
                    this.treeControl.expand(node);
            }
        }
    };
    OrgTreeComponent.prototype.trackNodesBy = function (i, node) {
        return node.id;
    };
    OrgTreeComponent.prototype.findNode = function (nodes, id) {
        for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
            var node = nodes_1[_i];
            if (node.id == id)
                return node;
            var foundChild = this.findNode(node.allChildren, id);
            if (foundChild)
                return foundChild;
        }
        return null;
    };
    OrgTreeComponent.prototype.ngOnInit = function () {
    };
    OrgTreeComponent.prototype.dividerMargin = function (node) {
        return 48 + node.level * 40 + 'px';
    };
    OrgTreeComponent.prototype.nodeToggled = function (node) {
        if (!node.hasChildren)
            return;
        var expanded = this.urlBinding.values.expanded.filter(function (value) { return value != node.id; });
        if (this.treeControl.isExpanded(node)) {
            expanded.push(node.id);
        }
        this.urlBinding.values.expanded = expanded;
    };
    return OrgTreeComponent;
}());
export { OrgTreeComponent };
