import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HolidayService = /** @class */ (function () {
    function HolidayService(http) {
        this.http = http;
    }
    HolidayService.prototype.listHolidays = function () {
        return this.http.get('/api/holiday');
    };
    HolidayService.prototype.currentHolidays = function () {
        return this.http.get('/api/holiday/current');
    };
    HolidayService.prototype.getHolidayById = function (id) {
        return this.http.get('/api/holiday/' + id);
    };
    HolidayService.prototype.saveHoliday = function (holiday) {
        return this.http.post('/api/holiday', holiday);
    };
    HolidayService.prototype.deleteHoliday = function (holidayId) {
        return this.http.delete('/api/holiday/' + holidayId);
    };
    HolidayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HolidayService_Factory() { return new HolidayService(i0.ɵɵinject(i1.HttpClient)); }, token: HolidayService, providedIn: "root" });
    return HolidayService;
}());
export { HolidayService };
