var PickerResponse = /** @class */ (function () {
    function PickerResponse(data) {
        this.data = data;
    }
    Object.defineProperty(PickerResponse.prototype, "documents", {
        get: function () {
            var docs = this.data[google.picker.Response.DOCUMENTS];
            return docs.map(function (doc) {
                var newDoc = Object.assign({}, google.picker.Document);
                for (var _i = 0, _a = Object.keys(google.picker.Document); _i < _a.length; _i++) {
                    var propName = _a[_i];
                    newDoc[propName] = doc[google.picker.Document[propName]];
                }
                return newDoc;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickerResponse.prototype, "parents", {
        get: function () {
            return this.data[google.picker.Response.PARENTS];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickerResponse.prototype, "view", {
        get: function () {
            return this.data[google.picker.Response.VIEW];
        },
        enumerable: true,
        configurable: true
    });
    return PickerResponse;
}());
export { PickerResponse };
