import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PersonService } from '../../person.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PersonWithStaff } from '../../person';
var QuickAddComponent = /** @class */ (function () {
    function QuickAddComponent(personService, data, dialogRef) {
        this.personService = personService;
        this.data = data;
        this.dialogRef = dialogRef;
        this.person = new PersonWithStaff();
    }
    QuickAddComponent.prototype.ngOnInit = function () {
    };
    QuickAddComponent.prototype.cancel = function () {
        this.dialogRef.close();
    };
    QuickAddComponent.prototype.add = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.personService.updatePerson(this.person)];
                    case 1:
                        _a.person = _b.sent();
                        this.dialogRef.close(this.person);
                        return [2 /*return*/];
                }
            });
        });
    };
    return QuickAddComponent;
}());
export { QuickAddComponent };
