import { LoginService } from './login.service';
import * as i0 from "@angular/core";
import * as i1 from "./login.service";
var PolicyService = /** @class */ (function () {
    function PolicyService(loginService) {
        this.loginService = loginService;
        this.policies = {
            viewSalary: function (user) { return user.hasAnyRole(['admin', 'hradmin']); },
            orgGroupManager: function (user) { return user.hasAnyRole(['admin', 'hr']) || user.isHigherup; },
            orgChart: function (user) { return user.hasAnyRole(['admin', 'hr']) || user.isSupervisor; },
            leaveManager: function (user) { return user.hasAnyRole(['admin', 'hr']) || user.isHigherup; },
            staffLeaveManager: function (user) { return user.isHrOrAdmin() || user.isLeaveDelegate || user.isSupervisor; },
            leaveSupervisor: function (user) { return user.isLeaveDelegate || user.isSupervisor; },
            isSupervisor: function (user) { return user.isSupervisor; },
            endorsementManager: function (user) { return user.hasAnyRole(['admin', 'hr']) || user.isHigherup; },
            peopleEdit: function (user) { return user.hasAnyRole(['admin', 'hr', 'registrar']) || user.isSupervisor; },
            missionOrgManager: function (user) { return user.hasAnyRole(['admin', 'hr', 'registrar']) || user.isHigherup; },
            hrEdit: function (user) { return user.isHrOrAdmin() || user.isHigherup; },
            addRoles: function (user) { return user.isHrOrAdmin() || user.isHigherup; },
            addEvaluations: function (user) { return user.isHrOrAdmin() || user.isHigherup; },
            addTraining: function (user) { return user.isHrOrAdmin() || user.isHigherup; },
            registrarEdit: function (user) { return user.hasAnyRole(['admin', 'registrar']); },
            userManager: function (user) { return user.hasRole('admin'); },
            impersonate: function (user) { return user.hasRole('admin'); }
        };
    }
    PolicyService.prototype.getPolicy = function (policy) {
        return this.policies[policy];
    };
    PolicyService.prototype.testPolicy = function (policy) {
        var policyFunction = this.getPolicy(policy);
        return policyFunction && policyFunction(this.loginService.userToken);
    };
    PolicyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.ɵɵinject(i1.LoginService)); }, token: PolicyService, providedIn: "root" });
    return PolicyService;
}());
export { PolicyService };
