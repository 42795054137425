import * as tslib_1 from "tslib";
import { BaseEntity } from '../../classes/base-entity';
var StaffTraining = /** @class */ (function (_super) {
    tslib_1.__extends(StaffTraining, _super);
    function StaffTraining() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StaffTraining.getKey = function (staffTraining) {
        return staffTraining.staffId + '_' + staffTraining.trainingRequirementId;
    };
    StaffTraining.New = function (staffId, trainingId) {
        var training = new StaffTraining();
        training.staffId = staffId;
        training.trainingRequirementId = trainingId;
        return training;
    };
    return StaffTraining;
}(BaseEntity));
export { StaffTraining };
var StaffTrainingWithRequirement = /** @class */ (function (_super) {
    tslib_1.__extends(StaffTrainingWithRequirement, _super);
    function StaffTrainingWithRequirement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return StaffTrainingWithRequirement;
}(StaffTraining));
export { StaffTrainingWithRequirement };
