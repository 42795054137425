import * as tslib_1 from "tslib";
import { BaseEntity } from 'app/classes/base-entity';
var Holiday = /** @class */ (function (_super) {
    tslib_1.__extends(Holiday, _super);
    function Holiday() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Holiday;
}(BaseEntity));
export { Holiday };
