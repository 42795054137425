import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
export var Gender;
(function (Gender) {
    Gender["Male"] = "Male";
    Gender["Female"] = "Female";
})(Gender || (Gender = {}));
var Person = /** @class */ (function (_super) {
    tslib_1.__extends(Person, _super);
    function Person() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.gender = Gender.Male;
        return _this;
    }
    return Person;
}(BaseEntity));
export { Person };
var PersonExtended = /** @class */ (function (_super) {
    tslib_1.__extends(PersonExtended, _super);
    function PersonExtended() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PersonExtended;
}(Person));
export { PersonExtended };
var PersonWithRoleSummaries = /** @class */ (function (_super) {
    tslib_1.__extends(PersonWithRoleSummaries, _super);
    function PersonWithRoleSummaries() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PersonWithRoleSummaries;
}(PersonExtended));
export { PersonWithRoleSummaries };
var PersonWithStaff = /** @class */ (function (_super) {
    tslib_1.__extends(PersonWithStaff, _super);
    function PersonWithStaff() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PersonWithStaff;
}(PersonExtended));
export { PersonWithStaff };
var PersonWithStaffSummaries = /** @class */ (function (_super) {
    tslib_1.__extends(PersonWithStaffSummaries, _super);
    function PersonWithStaffSummaries() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PersonWithStaffSummaries;
}(PersonWithStaff));
export { PersonWithStaffSummaries };
var PersonWithOthers = /** @class */ (function (_super) {
    tslib_1.__extends(PersonWithOthers, _super);
    function PersonWithOthers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.roles = [];
        _this.emergencyContacts = [];
        _this.evaluations = [];
        _this.staffEndorsements = [];
        _this.donations = [];
        _this.education = [];
        return _this;
    }
    return PersonWithOthers;
}(PersonWithStaff));
export { PersonWithOthers };
var PersonWithDaysOfLeave = /** @class */ (function (_super) {
    tslib_1.__extends(PersonWithDaysOfLeave, _super);
    function PersonWithDaysOfLeave() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PersonWithDaysOfLeave;
}(Person));
export { PersonWithDaysOfLeave };
