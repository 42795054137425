import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
var Job = /** @class */ (function (_super) {
    tslib_1.__extends(Job, _super);
    function Job() {
        var _this = _super.call(this) || this;
        _this.current = true;
        return _this;
    }
    return Job;
}(BaseEntity));
export { Job };
var JobWithFilledInfo = /** @class */ (function (_super) {
    tslib_1.__extends(JobWithFilledInfo, _super);
    function JobWithFilledInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return JobWithFilledInfo;
}(Job));
export { JobWithFilledInfo };
var JobWithOrgGroup = /** @class */ (function (_super) {
    tslib_1.__extends(JobWithOrgGroup, _super);
    function JobWithOrgGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return JobWithOrgGroup;
}(Job));
export { JobWithOrgGroup };
var JobWithRoles = /** @class */ (function (_super) {
    tslib_1.__extends(JobWithRoles, _super);
    function JobWithRoles() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.roles = [];
        _this.requiredEndorsements = [];
        return _this;
    }
    return JobWithRoles;
}(Job));
export { JobWithRoles };
export var JobStatus;
(function (JobStatus) {
    JobStatus["FullTime"] = "FullTime";
    JobStatus["FullTime10Mo"] = "FullTime10Mo";
    JobStatus["HalfTime"] = "HalfTime";
    JobStatus["Contractor"] = "Contractor";
    JobStatus["DailyWorker"] = "DailyWorker";
    JobStatus["SchoolAid"] = "SchoolAid";
})(JobStatus || (JobStatus = {}));
var ɵ1 = function (value) { return JobStatus[value]; }, ɵ0 = function (value) { return value != JobStatus.SchoolAid; };
export var NonSchoolAidJobStatus = Object.keys(JobStatus)
    .map(ɵ1)
    .filter(ɵ0);
export var JobType;
(function (JobType) {
    JobType["Admin"] = "Admin";
    JobType["Support"] = "Support";
    JobType["Teacher"] = "Teacher";
    JobType["BlueCollar"] = "BlueCollar";
})(JobType || (JobType = {}));
var ɵ2 = function (value) { return JobType[value]; };
export var AllJobTypes = Object.keys(JobType)
    .map(ɵ2);
export { ɵ1, ɵ0, ɵ2 };
