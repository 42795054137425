import { EventEmitter, OnDestroy, ViewContainerRef } from '@angular/core';
import { AppTemplateService } from './app-template.service';
import { delay } from 'rxjs/operators';
var ToolbarTemplateDirective = /** @class */ (function () {
    function ToolbarTemplateDirective(viewContainer, toolbarService) {
        this.viewContainer = viewContainer;
        this.toolbarService = toolbarService;
        this.hasView = false;
        this.onHasView = new EventEmitter();
    }
    Object.defineProperty(ToolbarTemplateDirective.prototype, "templateName", {
        get: function () {
            return this._templateName;
        },
        set: function (templateName) {
            var _this = this;
            if (this.subscription)
                this.subscription.unsubscribe();
            this._templateName = templateName;
            this.subscription = this.toolbarService.ObserveTemplateRef(templateName || 'toolbar')
                .pipe(delay(0))
                .subscribe(function (templateRef) {
                var originalValue = _this.hasView;
                try {
                    if (_this.hasView) {
                        _this.viewContainer.clear();
                        _this.hasView = false;
                    }
                    if (!templateRef)
                        return;
                    _this.viewContainer.createEmbeddedView(templateRef);
                    _this.hasView = true;
                }
                finally {
                    if (originalValue != _this.hasView)
                        _this.onHasView.emit(_this.hasView);
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    ToolbarTemplateDirective.prototype.ngOnDestroy = function () {
        if (this.subscription)
            this.subscription.unsubscribe();
    };
    return ToolbarTemplateDirective;
}());
export { ToolbarTemplateDirective };
