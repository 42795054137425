import * as moment from 'moment';
var Year = /** @class */ (function () {
    function Year(value, name) {
        this.value = value;
        this.name = name;
    }
    Year.CurrentSchoolYear = function () {
        var date = moment();
        if ((date.month() + 1) >= Year.schoolStartMonth)
            return date.year();
        return date.year() - 1;
    };
    Year.schoolYear = function (date) {
        var momentDate = moment(date);
        return (momentDate.month() + 1) >= Year.schoolStartMonth ? momentDate.year() : momentDate.year() - 1;
    };
    Year.years = function () {
        var today = new Date();
        var years = new Array(today.getUTCFullYear() - 2000 + 3);
        for (var i = 0; i < years.length; i++) {
            var display = Year.yearName(i + 2000);
            years[i] = new Year(i + 2000, display);
        }
        return years.reverse();
    };
    Year.schoolYearNameFromDate = function (date) {
        return Year.yearName(Year.schoolYear(date));
    };
    Year.dateRangeIntersectsWithYear = function (startDate, endDate, year) {
        if (endDate == null)
            return Year.endOfYear(year).isAfter(startDate);
        if (Year.startOfYear(year).isBetween(startDate, endDate))
            return true;
        return Year.endOfYear(year).isBetween(startDate, endDate);
    };
    Year.yearName = function (year) {
        year -= 2000;
        if (year < 9) {
            return "0" + year + " - 0" + (year + 1);
        }
        else if (year === 9) {
            return '09 - 10';
        }
        else {
            return year + " - " + (year + 1);
        }
    };
    Year.startOfYear = function (year) {
        return moment(new Date(year, Year.schoolStartMonth + 1, 1));
    };
    Year.endOfYear = function (year) {
        return moment(new Date(year + 1, Year.schoolEndMonth + 1, 30));
    };
    Object.defineProperty(Year.prototype, "startOfYear", {
        get: function () {
            //month is 0 based
            return new Date(this.value, Year.schoolStartMonth + 1, 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Year.prototype, "endOfYear", {
        get: function () {
            //month is 0 based
            return new Date(this.value + 1, Year.schoolEndMonth + 1, 30);
        },
        enumerable: true,
        configurable: true
    });
    Year.prototype.convertToSchoolYear = function (date) {
        var mDate = moment(date).year(this.value);
        if ((mDate.month() + 1) >= Year.schoolStartMonth)
            return mDate;
        return mDate.add(1, 'y');
    };
    //month in base 1 for months
    Year.schoolStartMonth = 7;
    Year.schoolEndMonth = 6;
    return Year;
}());
export { Year };
