import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { of } from 'rxjs';
import { AttachmentService } from './attachment.service';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { BaseDestroy } from '../classes/base-destroy';
var AttachmentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AttachmentsComponent, _super);
    function AttachmentsComponent(attachmentService, dialog) {
        var _this = _super.call(this) || this;
        _this.attachmentService = attachmentService;
        _this.dialog = dialog;
        return _this;
    }
    AttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.attachmentService.extractId()
            .pipe(tap(function (value) { return _this.attachedToId = value.id; }), switchMap(function (value) {
            if (value.hasAttachments)
                return _this.attachmentService.attachedOn(value.id);
            return of([]);
        }), this.takeUntilDestroy())
            .subscribe(function (attachments) {
            _this.attachments = attachments;
        });
    };
    AttachmentsComponent.prototype.removeAttachment = function (attachment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete Attachment " + attachment.name + "?", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.attachmentService.removeAttachment(attachment.id).toPromise()];
                    case 2:
                        _a.sent();
                        this.attachments = this.attachments.filter(function (value) { return value.id != attachment.id; });
                        return [2 /*return*/];
                }
            });
        });
    };
    AttachmentsComponent.prototype.newAttachment = function (attachment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        attachment.attachedToId = this.attachedToId;
                        return [4 /*yield*/, this.attachmentService.attach(attachment).toPromise()];
                    case 1:
                        attachment = _a.sent();
                        this.attachments = [attachment].concat(this.attachments);
                        return [2 /*return*/];
                }
            });
        });
    };
    return AttachmentsComponent;
}(BaseDestroy));
export { AttachmentsComponent };
