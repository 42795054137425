import * as tslib_1 from "tslib";
import { BaseEntity } from '../../classes/base-entity';
export var TrainingScope;
(function (TrainingScope) {
    TrainingScope["AllStaff"] = "AllStaff";
    TrainingScope["Department"] = "Department";
})(TrainingScope || (TrainingScope = {}));
var TrainingRequirement = /** @class */ (function (_super) {
    tslib_1.__extends(TrainingRequirement, _super);
    function TrainingRequirement() {
        var _this = _super.call(this) || this;
        _this.renewMonthsCount = 12;
        _this.scope = TrainingScope.AllStaff;
        _this.firstYear = new Date().getUTCFullYear();
        return _this;
    }
    return TrainingRequirement;
}(BaseEntity));
export { TrainingRequirement };
