import * as tslib_1 from "tslib";
import { BaseEntity } from '../../classes/base-entity';
var LeaveRequest = /** @class */ (function (_super) {
    tslib_1.__extends(LeaveRequest, _super);
    function LeaveRequest() {
        var _this = _super.call(this) || this;
        _this.approved = null;
        _this.createdDate = new Date();
        _this.startDate = null;
        _this.endDate = null;
        return _this;
    }
    return LeaveRequest;
}(BaseEntity));
export { LeaveRequest };
var LeaveRequestWithNames = /** @class */ (function (_super) {
    tslib_1.__extends(LeaveRequestWithNames, _super);
    function LeaveRequestWithNames() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LeaveRequestWithNames;
}(LeaveRequest));
export { LeaveRequestWithNames };
var LeaveRequestPublic = /** @class */ (function () {
    function LeaveRequestPublic() {
        this.isPublic = true;
    }
    return LeaveRequestPublic;
}());
export { LeaveRequestPublic };
