import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isArray } from 'util';
var UrlBindingService = /** @class */ (function () {
    function UrlBindingService(route, router) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.subjects = [];
        this._observableValues = null;
        this._values = null;
        this.params = [];
        this.defaultValues = [];
        this.pathParams = [];
        this.ignoreUpdates = false;
        this.onParamsUpdated = function (values) {
        };
        combineLatest(this.route.paramMap, this.route.queryParamMap)
            .pipe(debounceTime(1))
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this._loadFromParams(params, queryParams);
        });
    }
    Object.defineProperty(UrlBindingService.prototype, "values", {
        get: function () {
            if (this._values)
                return this._values;
            this._values = {};
            var _loop_1 = function (i) {
                var name_1 = this_1.params[i];
                var subject = this_1.subjects[i];
                Object.defineProperty(this_1._values, name_1, {
                    get: function () {
                        return subject.value;
                    },
                    set: function (value) {
                        subject.next(value);
                    },
                });
            };
            var this_1 = this;
            for (var i = 0; i < this.params.length; i++) {
                _loop_1(i);
            }
            return this._values;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlBindingService.prototype, "observableValues", {
        get: function () {
            if (this._observableValues)
                return this._observableValues;
            this._observableValues = {};
            for (var i = 0; i < this.params.length; i++) {
                var name_2 = this.params[i];
                var subject = this.subjects[i];
                Object.defineProperty(this._observableValues, name_2, {
                    value: subject
                });
            }
            return this._observableValues;
        },
        enumerable: true,
        configurable: true
    });
    UrlBindingService.prototype.loadFromParams = function () {
        return this._loadFromParams(this.route.snapshot.paramMap, this.route.snapshot.queryParamMap);
    };
    UrlBindingService.prototype.addParam = function (name, defaultValue, pathParam) {
        if (pathParam === void 0) { pathParam = false; }
        this.params.push(name);
        this.pathParams.push(pathParam);
        this.defaultValues.push(defaultValue);
        var subject = new BehaviorSubject(defaultValue);
        this.subjects.push(subject);
        this.ignoreUpdates = true;
        this.updateSubscription();
        this.ignoreUpdates = false;
        return subject.asObservable();
    };
    UrlBindingService.prototype._loadFromParams = function (params, queryParams) {
        this.ignoreUpdates = true;
        var hadUpdate = false;
        for (var i = 0; i < this.params.length; i++) {
            if (this.loadParam(i, queryParams))
                hadUpdate = true;
            else if (this.loadParam(i, params))
                hadUpdate = true;
        }
        this.ignoreUpdates = false;
        if (hadUpdate)
            this.onParamsUpdated(this.values);
        return hadUpdate;
    };
    UrlBindingService.prototype.loadParam = function (i, params) {
        var hadUpdate = false;
        var name = this.params[i];
        if (!params.has(name))
            return false;
        var values = params.getAll(name);
        var listOfValues = isArray(this.defaultValues[i]);
        switch (typeof this.defaultValues[i]) {
            case 'number':
                values = values.map(function (value) { return Number(value); });
                break;
            case 'boolean':
                // noinspection TsLint
                values = values.map(function (value) { return value != 'false'; });
                break;
        }
        var subject = this.subjects[i];
        if (listOfValues) {
            if (!this.areListsEqual(subject.value, values)) {
                subject.next(values);
                hadUpdate = true;
            }
        }
        else {
            var value = values[0];
            if (subject.value != value) {
                subject.next(value);
                hadUpdate = true;
            }
        }
        return hadUpdate;
    };
    UrlBindingService.prototype.areListsEqual = function (a, b) {
        if (a === b)
            return true;
        if (a == null || b == null)
            return false;
        if (a.length !== b.length)
            return false;
        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        a = a.slice();
        b = b.slice();
        a.sort();
        b.sort();
        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i])
                return false;
        }
        return true;
    };
    UrlBindingService.prototype.updateSubscription = function () {
        var _this = this;
        if (this.subjectsSubscription)
            this.subjectsSubscription.unsubscribe();
        this._values = null;
        this.subjectsSubscription = combineLatest(this.subjects).subscribe(function (values) {
            if (_this.ignoreUpdates)
                return;
            var params = {};
            var commands = ['.'];
            for (var i = 0; i < _this.params.length; i++) {
                var value = values[i];
                var defaultValue = _this.defaultValues[i];
                var listOfValues = isArray(defaultValue);
                if (_this.pathParams[i]) {
                    if (!_this.route.snapshot.paramMap.has(_this.params[i])) {
                        commands = commands.concat([value]);
                    }
                    else if (_this.route.snapshot.paramMap.get(_this.params[i]) != value) {
                        commands = commands.length === 1 ? ['..', value] : ['..'].concat(commands, [value]);
                    }
                }
                else if (listOfValues ? !_this.areListsEqual(value, defaultValue) : value !== defaultValue) {
                    params[_this.params[i]] = value;
                }
            }
            _this.router.navigate(commands, {
                relativeTo: _this.route,
                queryParams: params,
                replaceUrl: true
            });
            _this.onParamsUpdated(_this.values);
        });
    };
    return UrlBindingService;
}());
export { UrlBindingService };
