import { HttpClient } from '@angular/common/http';
import { combineLatest, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { StaffTraining } from './staff-training';
import { RequirementWithStaff, StaffWithTraining } from './training-report/requirement-with-staff';
import { GroupService } from 'app/people/groups/group.service';
import { Year } from './year';
var TrainingRequirementService = /** @class */ (function () {
    function TrainingRequirementService(http, groupService) {
        this.http = http;
        this.groupService = groupService;
    }
    TrainingRequirementService.prototype.list = function () {
        return this.http.get('/api/training');
    };
    TrainingRequirementService.prototype.listMapped = function () {
        return this.list().pipe(map(function (value) {
            return new Map(value
                .map(function (training) { return [training.id, training]; }));
        }));
    };
    TrainingRequirementService.prototype.getTrainingByStaffId = function (staffId) {
        if (!staffId)
            return of([]);
        return this.http.get('/api/training/staff/' + staffId);
    };
    TrainingRequirementService.prototype.getStaffTrainingByYear = function (year) {
        return this.http.get('/api/training/staff/year/' + year);
    };
    TrainingRequirementService.prototype.getStaffTrainingByYearMapped = function (year) {
        return this.getStaffTrainingByYear(year).pipe(map(function (staffTrainings) {
            return new Map(staffTrainings
                .map(function (training) { return [StaffTraining.getKey(training), training]; }));
        }));
    };
    TrainingRequirementService.prototype.get = function (id) {
        return this.http.get('/api/training/' + id);
    };
    TrainingRequirementService.prototype.save = function (training) {
        return this.http.post('/api/training/', training).toPromise();
    };
    TrainingRequirementService.prototype.deleteRequirement = function (id) {
        return this.http.delete('/api/training/' + id, { responseType: 'text' }).toPromise();
    };
    TrainingRequirementService.prototype.saveStaffTraining = function (staffTraining) {
        return this.http.post('/api/training/staff', staffTraining).toPromise();
    };
    TrainingRequirementService.prototype.deleteStaffTraining = function (id) {
        return this.http.delete('/api/training/staff/' + id, { responseType: 'text' }).toPromise();
    };
    TrainingRequirementService.prototype.markAllComplete = function (staffList, requirementId, completeDate) {
        return this.http.post('/api/training/staff/allComplete', staffList, {
            params: {
                'completeDate': completeDate.toISOString(),
                'requirementId': requirementId
            }, responseType: 'text'
        }).toPromise();
    };
    TrainingRequirementService.prototype.buildRequirementsWithStaff = function (staffObservable, orgGroupsObservable, requirementsObservable, staffTrainingObservable, yearObservable, showCompletedObservable) {
        var _this = this;
        return combineLatest(staffTrainingObservable, staffObservable, requirementsObservable, yearObservable, showCompletedObservable, orgGroupsObservable)
            .pipe(debounceTime(20), map(function (_a) {
            var staffTraining = _a[0], staff = _a[1], requirements = _a[2], year = _a[3], showCompleted = _a[4], orgGroups = _a[5];
            return requirements
                .filter(_this.isInYear.bind(_this, year))
                // .map(this.buildRequirementWithStaff.bind(this, staff, staffTraining, orgGroups, showCompleted))
                .map(function (requirement) { return _this.buildRequirementWithStaff(staff, staffTraining, orgGroups, showCompleted, requirement, year); })
                .filter(function (requirement, i, a) { return requirement.totalStaff > 0 && showCompleted ?
                true :
                (requirement.staffsWithTraining.length > 0); });
        }));
    };
    TrainingRequirementService.prototype.isInYear = function (year, requirement) {
        return requirement.firstYear <= year && (!requirement.lastYear || requirement.lastYear >= year);
    };
    TrainingRequirementService.prototype.buildRequirementWithStaff = function (staff, staffTraining, orgGroups, showCompleted, requirement, year) {
        var trainingForStaff = staff
            .map(function (staffMember) { return new StaffWithTraining(staffMember, staffTraining.get(staffMember.staffWithName.id + '_' + requirement.id)); })
            .filter(this.matchesTrainingSpecs.bind(this, orgGroups, requirement, year));
        var trainingFilteredByComplete = trainingForStaff.filter(this.filterCompletedTraining.bind(this, showCompleted));
        return new RequirementWithStaff(requirement, trainingFilteredByComplete, orgGroups.find(function (value) { return value.id == requirement.departmentId; }), trainingForStaff.length);
    };
    TrainingRequirementService.prototype.filterCompletedTraining = function (showCompleted, staffTraining) {
        if (showCompleted)
            return true;
        return staffTraining.training.completedDate == null;
    };
    TrainingRequirementService.prototype.matchesTrainingSpecs = function (orgGroups, requirement, year, staff) {
        if (requirement.jobScope && requirement.jobScope.length > 0) {
            if (!requirement.jobScope.some(function (value) { return staff.roles.some(function (role) { return role.job.type == value; }); }))
                return false;
            if (!staff.roles.some(function (role) { return Year.dateRangeIntersectsWithYear(role.startDate, role.endDate, year); }))
                return false;
        }
        return this.isInOrgGroup(orgGroups, requirement, staff);
    };
    TrainingRequirementService.prototype.isInOrgGroup = function (orgGroups, requirement, staff) {
        if (requirement.scope !== 'Department') {
            return true;
        }
        if (requirement.departmentId == null) {
            throw new Error('training requirement corrupt, missing department id');
        }
        if (staff.roles.length > 0) {
            for (var _i = 0, _a = staff.roles; _i < _a.length; _i++) {
                var role = _a[_i];
                if (this.groupService.isChildOf(role.job.orgGroupId, requirement.departmentId, orgGroups))
                    return true;
            }
        }
        return this.groupService.isChildOf(staff.staff.orgGroupId, requirement.departmentId, orgGroups);
    };
    return TrainingRequirementService;
}());
export { TrainingRequirementService };
