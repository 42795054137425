import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaveRequestService } from './leave-request.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../services/auth/login.service';
import { combineLatest } from 'rxjs';
import { PersonService } from '../person.service';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { LeaveType } from '../self/self';
import { Gender } from '../person';
import { BaseEditComponent } from '../../components/base-edit-component';
import { HolidayService } from '../../holiday/holiday.service';
var LeaveRequestComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LeaveRequestComponent, _super);
    function LeaveRequestComponent(route, router, leaveRequestService, snackBar, loginService, personService, location, holidayService, dialog) {
        var _this = _super.call(this, dialog) || this;
        _this.route = route;
        _this.router = router;
        _this.leaveRequestService = leaveRequestService;
        _this.snackBar = snackBar;
        _this.loginService = loginService;
        _this.personService = personService;
        _this.location = location;
        _this.holidayService = holidayService;
        _this.typesOfLeave = Object.keys(LeaveType);
        _this.daysUsed = 0;
        _this.isHr = false;
        _this.sendNotification = true;
        _this.noNotificationSnackbarRef = null;
        _this.holidays = [];
        _this.intersectingHolidays = '';
        return _this;
    }
    Object.defineProperty(LeaveRequestComponent.prototype, "isReadonly", {
        get: function () {
            return this.leaveRequest.approved !== null && !this.isHr;
        },
        enumerable: true,
        configurable: true
    });
    LeaveRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = combineLatest(this.route.data, this.loginService.safeUserToken(), this.route.queryParams)
            .subscribe(function (_a) {
            var data = _a[0], user = _a[1], queryParams = _a[2];
            _this.people = data.people;
            _this.leaveRequest = data.leaveRequest;
            _this.isNew = !_this.leaveRequest.id;
            _this.isHr = user.isHrOrAdmin();
            _this.updateDaysUsed();
            _this.sendNotification = (!_this.isHr || (!queryParams.noNotification && _this.isHr));
            if (!_this.sendNotification && _this.isNew) {
                setTimeout(function () { return _this.warnNoLeaveNotification(); });
            }
            _this.myPersonId = user.personId;
            var person = _this.people.find(function (p) { return p.person.id === (_this.leaveRequest.personId || user.personId); });
            if (person) {
                _this.leaveRequest.personId = person.person.id;
                _this.selectedPerson = person;
            }
        });
        this.holidayService.currentHolidays().subscribe(function (holidays) {
            _this.holidays = holidays;
            _this.updateDaysUsed();
        });
    };
    LeaveRequestComponent.prototype.warnNoLeaveNotification = function (sendNotification) {
        if (sendNotification === void 0) { sendNotification = false; }
        if (sendNotification) {
            if (this.noNotificationSnackbarRef)
                this.noNotificationSnackbarRef.dismiss();
            return;
        }
        this.noNotificationSnackbarRef = this.snackBar.open("Approval Emails won't be sent for this leave request", 'Dismiss');
    };
    LeaveRequestComponent.prototype.updateDaysUsed = function () {
        if ((this.leaveRequest.startDate && !this.leaveRequest.endDate) || this.leaveRequestService.isStartAfterEnd(this.leaveRequest)) {
            this.leaveRequest.endDate = this.leaveRequest.startDate;
        }
        if (this.leaveRequest && !this.leaveRequest.approved && !this.leaveRequest.overrideDays && this.leaveRequest.startDate && this.leaveRequest.endDate) {
            var result = this.leaveRequestService.weekDays(this.leaveRequest, this.holidays);
            this.leaveRequest.days = result.days;
            this.intersectingHolidays = result.countedHolidays;
        }
    };
    LeaveRequestComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    LeaveRequestComponent.prototype.submit = function (isDraft) {
        if (isDraft === void 0) { isDraft = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.isReadonly)
                            return [2 /*return*/];
                        if (isDraft)
                            this.formSubmitted = true;
                        _a = this.isNew;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.promptNewRequest()];
                    case 1:
                        _a = !(_b.sent());
                        _b.label = 2;
                    case 2:
                        if (_a) {
                            return [2 /*return*/];
                        }
                        if (!(!isDraft && this.sendNotification)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.submitLeaveRequest()];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.saveRequest(isDraft)];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LeaveRequestComponent.prototype.promptNewRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var overUsingLeave, doctorsNote;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        overUsingLeave = this.leaveRequestService.isOverUsingLeave(this.leaveRequest, this.selectedPerson.leaveUsages);
                        doctorsNote = this.leaveRequest.type == LeaveType.Sick && (this.leaveRequest.days > 2);
                        if (!(overUsingLeave || doctorsNote)) return [3 /*break*/, 2];
                        return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, (overUsingLeave ? "This leave request is using more leave than you have\n" : '')
                                + (doctorsNote ? "You must email a doctors note to HR" : ''), 'Request Leave', 'Cancel')];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    LeaveRequestComponent.prototype.submitLeaveRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var notified, message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.leaveRequestService.requestLeave(this.leaveRequest)];
                    case 1:
                        notified = _a.sent();
                        if (!notified) {
                            message = "Leave request " + (this.isNew ? 'created' : 'updated') + ", supervisor not found, no notification was sent";
                        }
                        else {
                            message = "Leave request " + (this.isNew ?
                                'created' :
                                'updated') + ", notified " + notified.firstName + " " + notified.lastName;
                        }
                        this.snackBar.open(message, 'Dismiss');
                        this.router.navigate([
                            'leave-request',
                            'list',
                            this.myPersonId === this.leaveRequest.personId ? 'mine' : this.leaveRequest.personId
                        ]);
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaveRequestComponent.prototype.saveRequest = function (isDraft) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.leaveRequestService.updateLeave(this.leaveRequest).toPromise()];
                    case 1:
                        _a.sent();
                        message = 'Leave updated, notification was not sent of changes';
                        if (isDraft)
                            message = 'Leave Request draft saved';
                        this.snackBar.open(message, null, { duration: 2000 });
                        this.location.back();
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaveRequestComponent.prototype.promptSendNotification = function () {
        if (this.leaveRequest.approved)
            return Promise.resolve(false);
        return ConfirmDialogComponent.OpenWait(this.dialog, 'Send another leave request approval email?', 'Send Email & Save', 'Save');
    };
    LeaveRequestComponent.prototype.personSelectedChanged = function (personId) {
        this.selectedPerson = this.people.find(function (value) { return value.person.id === personId; });
    };
    LeaveRequestComponent.prototype.overrideDaysChanged = function (overrideDays) {
        if (!overrideDays) {
            this.updateDaysUsed();
        }
    };
    LeaveRequestComponent.prototype.deleteRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete Request for " + this.selectedPerson.person.preferredName + "?", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.leaveRequestService.deleteRequest(this.leaveRequest.id).toPromise()];
                    case 2:
                        _a.sent();
                        this.snackBar.open('Request deleted', null, { duration: 2000 });
                        this.location.back();
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaveRequestComponent.prototype.showLeaveUsage = function (leaveUsage) {
        if (!this.showLeaveType(leaveUsage.leaveType))
            return false;
        return leaveUsage.totalAllowed != 0 || leaveUsage.used != 0;
    };
    LeaveRequestComponent.prototype.showLeaveType = function (leaveType) {
        if (!this.selectedPerson)
            return true;
        switch (leaveType) {
            case LeaveType.Vacation:
                return this.selectedPerson.person.isThai;
            case LeaveType.Paternity:
                return this.selectedPerson.person.gender == Gender.Male;
            case LeaveType.Maternity:
                return this.selectedPerson.person.gender == Gender.Female;
            default:
                return true;
        }
    };
    LeaveRequestComponent.prototype.approvedChanged = function (approved) {
        if (approved) {
            this.leaveRequest.approvedById = this.myPersonId;
        }
        else {
            this.leaveRequest.approvedById = null;
        }
    };
    return LeaveRequestComponent;
}(BaseEditComponent));
export { LeaveRequestComponent };
