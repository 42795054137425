import * as tslib_1 from "tslib";
import { BaseEntity } from '../../classes/base-entity';
var OrgGroup = /** @class */ (function (_super) {
    tslib_1.__extends(OrgGroup, _super);
    function OrgGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrgGroup;
}(BaseEntity));
export { OrgGroup };
var OrgGroupWithSupervisor = /** @class */ (function (_super) {
    tslib_1.__extends(OrgGroupWithSupervisor, _super);
    function OrgGroupWithSupervisor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrgGroupWithSupervisor;
}(OrgGroup));
export { OrgGroupWithSupervisor };
export var GroupType;
(function (GroupType) {
    GroupType["Division"] = "Division";
    GroupType["Department"] = "Department";
    GroupType["Supervisor"] = "Supervisor";
})(GroupType || (GroupType = {}));
