import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
var Endorsement = /** @class */ (function (_super) {
    tslib_1.__extends(Endorsement, _super);
    function Endorsement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Endorsement;
}(BaseEntity));
export { Endorsement };
var StaffEndorsement = /** @class */ (function (_super) {
    tslib_1.__extends(StaffEndorsement, _super);
    function StaffEndorsement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return StaffEndorsement;
}(BaseEntity));
export { StaffEndorsement };
var StaffEndorsementWithName = /** @class */ (function (_super) {
    tslib_1.__extends(StaffEndorsementWithName, _super);
    function StaffEndorsementWithName(personId) {
        var _this = _super.call(this) || this;
        _this.personId = personId;
        return _this;
    }
    return StaffEndorsementWithName;
}(StaffEndorsement));
export { StaffEndorsementWithName };
var RequiredEndorsement = /** @class */ (function (_super) {
    tslib_1.__extends(RequiredEndorsement, _super);
    function RequiredEndorsement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequiredEndorsement;
}(BaseEntity));
export { RequiredEndorsement };
var RequiredEndorsementWithName = /** @class */ (function (_super) {
    tslib_1.__extends(RequiredEndorsementWithName, _super);
    function RequiredEndorsementWithName(jobId) {
        var _this = _super.call(this) || this;
        _this.jobId = jobId;
        return _this;
    }
    return RequiredEndorsementWithName;
}(RequiredEndorsement));
export { RequiredEndorsementWithName };
