import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
var SandboxComponent = /** @class */ (function () {
    function SandboxComponent(http) {
        this.http = http;
    }
    SandboxComponent.prototype.ngOnInit = function () {
    };
    SandboxComponent.prototype.test = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var val;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('date:', this.date.toJSON());
                        return [4 /*yield*/, this.http.post('api/test', { date: this.date }).toPromise()];
                    case 1:
                        val = _a.sent();
                        console.log('date back:', moment(val.date).toISOString(true));
                        return [2 /*return*/];
                }
            });
        });
    };
    return SandboxComponent;
}());
export { SandboxComponent };
