import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { GoogleApiService } from 'ng-gapi';
import { SettingsService } from '../services/settings.service';
import { Attachment } from '../attachments/attachment';
import { PickerResponse } from './picker-response';
import { ActivityIndicatorService } from '../services/activity-indicator.service';
var DrivePickerService = /** @class */ (function () {
    function DrivePickerService(injector, settings, indicatorService) {
        this.injector = injector;
        this.settings = settings;
        this.indicatorService = indicatorService;
        this.loaded = false;
    }
    DrivePickerService.prototype.signIn = function () {
        if (!this.loaded)
            throw new Error('load must be called first');
        var authInstance = gapi.auth2.getAuthInstance();
        if (authInstance.isSignedIn.get())
            return Promise.resolve();
        return authInstance.signIn()
            .then(function () {
            return authInstance.isSignedIn.get();
        });
    };
    DrivePickerService.prototype.loadPicker = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var googleApiService;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.loaded)
                            return [2 /*return*/];
                        googleApiService = this.injector.get(GoogleApiService);
                        return [4 /*yield*/, googleApiService.onLoad().toPromise()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, new Promise(function (resolve) {
                                gapi.load('client:picker:auth2', function () {
                                    resolve();
                                });
                            })];
                    case 2:
                        _a.sent();
                        // @ts-ignore
                        return [4 /*yield*/, gapi.auth2.init(googleApiService.getConfig().getClientConfig())];
                    case 3:
                        // @ts-ignore
                        _a.sent();
                        this.loaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    DrivePickerService.prototype.openPicker = function () {
        var _this = this;
        this.indicatorService.showIndicator();
        console.log('open picker');
        var gisTeamDriveId = '0ANi-SiRomIBKUk9PVA';
        return this.loadPicker()
            .then(function () { return _this.signIn(); })
            .then(function () {
            return new Promise(function (resolve) {
                var authInstance = gapi.auth2.getAuthInstance();
                var googleUser = authInstance.currentUser.get();
                _this.activePicker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
                    .addView(new google.picker.DocsView()
                    .setParent(gisTeamDriveId)
                    .setIncludeFolders(true))
                    .addView(new google.picker.DocsUploadView()
                    .setParent(gisTeamDriveId)
                    .setIncludeFolders(true))
                    .setOAuthToken(googleUser.getAuthResponse().access_token)
                    .setDeveloperKey(_this.settings.get('googleAPIKey'))
                    .setCallback(function (data) {
                    if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED)
                        resolve(new PickerResponse(data));
                    if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL)
                        resolve(null);
                })
                    .build();
                _this.activePicker.setVisible(true);
                _this.indicatorService.hideIndicator();
            });
        });
    };
    /*
    {
    "action": "picked",
    "viewToken": [
      "all",
      null,
      {
        "parent": "0ANi-SiRomIBKUk9PVA",
        "includeFolders": true
      }
    ],
    "docs": [
      {
        "id": "1mII6lISg4uEKJNkXMXeT_lKV96L1UxIR",
        "serviceId": "docs",
        "mimeType": "image/png",
        "name": "Nyan.png",
        "description": "",
        "type": "photo",
        "lastEditedUtc": 1520560551111,
        "iconUrl": "https://drive-thirdparty.googleusercontent.com/16/type/image/png",
        "url": "https://drive.google.com/file/d/1mII6lISg4uEKJNkXMXeT_lKV96L1UxIR/view?usp=drive_web",
        "embedUrl": "https://drive.google.com/file/d/1mII6lISg4uEKJNkXMXeT_lKV96L1UxIR/preview?usp=drive_web",
        "sizeBytes": 26158,
        "teamDriveId": "1uC90-ts_5E1173eEEsaXGZpXzVGrZx9Qm28",
        "rotation": 0,
        "rotationDegree": 0,
        "parentId": "0ANi-SiRomIBKUk9PVA",
        "isShared": true
      }
    ]
  }
     */
    //above is an example result, the first doc is what gets returned
    DrivePickerService.prototype.convertToAttachment = function (doc) {
        var attachment = new Attachment();
        attachment.googleId = doc.ID;
        attachment.name = doc.NAME;
        attachment.downloadUrl = doc.URL;
        attachment.fileType = doc.TYPE;
        return attachment;
    };
    return DrivePickerService;
}());
export { DrivePickerService };
