import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingRequirementService } from '../training-requirement.service';
import { TrainingScope } from '../training-requirement';
import { Year } from '../year';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../dialog/confirm-dialog/confirm-dialog.component';
import { BaseEditComponent } from '../../../components/base-edit-component';
import { JobType } from '../../../job/job';
var TrainingEditComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TrainingEditComponent, _super);
    function TrainingEditComponent(route, trainingService, router, dialog, snackBar) {
        var _this = _super.call(this, dialog) || this;
        _this.route = route;
        _this.trainingService = trainingService;
        _this.router = router;
        _this.snackBar = snackBar;
        _this.jobTypes = Object.keys(JobType);
        _this.isNew = false;
        return _this;
    }
    TrainingEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (value) {
            _this.groups = value.groups;
            _this.people = value.people;
            _this.training = value.training;
            _this.isNew = !_this.training.id;
        });
        this.years = Year.years();
    };
    Object.defineProperty(TrainingEditComponent.prototype, "for", {
        get: function () {
            if (this.training.scope !== TrainingScope.Department)
                return this.training.scope;
            return this.training.departmentId;
        },
        set: function (value) {
            if (value == TrainingScope.AllStaff) {
                this.training.scope = value;
            }
            else {
                this.training.scope = TrainingScope.Department;
                this.training.departmentId = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    TrainingEditComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.trainingService.save(this.training)];
                    case 1:
                        _a.sent();
                        this.router.navigate(['training', 'list']);
                        this.snackBar.open("Training Requirement " + (this.isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingEditComponent.prototype.delete = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Deleting Training requirement, any training records will also be deleted", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.trainingService.deleteRequirement(this.training.id)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['training', 'list'], { relativeTo: this.route });
                        this.snackBar.open("Training Requirement Deleted", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    return TrainingEditComponent;
}(BaseEditComponent));
export { TrainingEditComponent };
