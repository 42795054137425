import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
var AccordionListComponent = /** @class */ (function () {
    function AccordionListComponent(snackBar, dialog, element) {
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.element = element;
        this.caption = null;
        this.itemTitle = 'Item';
        this.addNew = true;
        this.showActions = true;
        this.items = [];
        this.itemsChange = new EventEmitter();
        this.forms = [];
    }
    AccordionListComponent.prototype.onDelete = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, deleteResult;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete " + this.itemTitle + "?", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        deleteResult = this.delete(item);
                        if ('toPromise' in deleteResult)
                            deleteResult = deleteResult.toPromise();
                        return [4 /*yield*/, deleteResult];
                    case 2:
                        _a.sent();
                        if ('id' in item) {
                            this.itemsChange.emit(this.items.filter(function (value) { return value.id != item.id; }));
                        }
                        this.snackBar.open(this.itemTitle + " Deleted", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    AccordionListComponent.prototype.onSave = function (item, panel, form, isNew) {
        if (isNew === void 0) { isNew = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var saveResult;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        saveResult = this.save(item);
                        if ('toPromise' in saveResult)
                            saveResult = saveResult.toPromise();
                        return [4 /*yield*/, saveResult];
                    case 1:
                        item = _a.sent();
                        this.snackBar.open(this.itemTitle + " " + (isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        panel.close();
                        if (isNew) {
                            this.itemsChange.emit(this.items.concat([item]));
                            this.newItem = this.createNewItem();
                            form.resetForm();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AccordionListComponent.prototype.ngOnInit = function () {
        if (this.save == null)
            throw new Error('Save callback not provided');
        if (this.delete == null)
            throw new Error('Delete callback not provided');
        if (this.header == null)
            throw new Error('Missing *appAccordionListHeader');
        if (this.content == null)
            throw new Error('Missing *appAccordionListContent');
    };
    AccordionListComponent.prototype.findIndex = function (form) {
        var foundIndex = -1;
        this.expansionPanelElements.forEach(function (item, index) {
            if (foundIndex != -1)
                return;
            if (form.hasAsParent(item.nativeElement)) {
                foundIndex = index;
            }
        });
        return foundIndex - 1;
    };
    AccordionListComponent.prototype.addForm = function (form) {
        this.forms = this.forms.concat([form]);
    };
    AccordionListComponent.prototype.removeForm = function (form) {
        this.forms = this.forms.filter(function (value) { return value == form; }).slice();
    };
    AccordionListComponent.prototype.findForm = function (index) {
        for (var _i = 0, _a = this.forms; _i < _a.length; _i++) {
            var form = _a[_i];
            if (form.getContext().index == index)
                return form;
        }
        return null;
    };
    AccordionListComponent.prototype.matchesElement = function (element) {
        return this.element.nativeElement == element;
    };
    return AccordionListComponent;
}());
export { AccordionListComponent };
