import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
var MissionOrgYearSummary = /** @class */ (function (_super) {
    tslib_1.__extends(MissionOrgYearSummary, _super);
    function MissionOrgYearSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MissionOrgYearSummary;
}(BaseEntity));
export { MissionOrgYearSummary };
export var MissionOrgLevel;
(function (MissionOrgLevel) {
    MissionOrgLevel["Bronze"] = "Bronze";
    MissionOrgLevel["Silver"] = "Silver";
    MissionOrgLevel["Gold"] = "Gold";
    MissionOrgLevel["Platinum"] = "Platinum";
})(MissionOrgLevel || (MissionOrgLevel = {}));
