import { isArray } from 'util';
import { GroupType } from '../people/groups/org-group';
var UserToken = /** @class */ (function () {
    function UserToken(token) {
        this.token = token;
    }
    Object.defineProperty(UserToken.prototype, "userName", {
        get: function () {
            return this.token && this.token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "expirationDate", {
        get: function () {
            if (!this.token)
                return new Date();
            //exp is expiration date in seconds from epoch
            return new Date(this.token.exp * 1000);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "email", {
        get: function () {
            return this.token && this.token['email'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "roles", {
        get: function () {
            var val = this.token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (isArray(val))
                return val;
            return [val];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "personId", {
        get: function () {
            if (!this.token)
                return null;
            return this.token['personId'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "oauth", {
        get: function () {
            if (!this.token)
                return null;
            return this.token['oauth'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "isSupervisor", {
        get: function () {
            if (!this.token)
                return false;
            return !!this.token['supervisesGroupId'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "isLeaveDelegate", {
        get: function () {
            if (!this.token)
                return false;
            return !!this.token['leaveDelegateGroupId'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "orgGroupId", {
        get: function () {
            if (!this.isSupervisor)
                return null;
            return this.token['supervisesGroupId'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "orgGroupType", {
        get: function () {
            if (!this.isSupervisor)
                return null;
            return this.token['supervisesGroupType'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserToken.prototype, "isHigherup", {
        get: function () {
            return this.orgGroupType == GroupType.Supervisor;
        },
        enumerable: true,
        configurable: true
    });
    UserToken.prototype.hasAnyRole = function (roles) {
        var _this = this;
        return roles.some(function (value) { return _this.roles.includes(value); });
    };
    UserToken.prototype.hasRole = function (role) {
        return this.roles.includes(role);
    };
    UserToken.prototype.isHrOrAdmin = function () {
        return this.hasAnyRole(['admin', 'hr']);
    };
    return UserToken;
}());
export { UserToken };
