import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
var MyDatePipe = /** @class */ (function (_super) {
    tslib_1.__extends(MyDatePipe, _super);
    function MyDatePipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyDatePipe.prototype.transform = function (value, args) {
        if (!args) {
            args = 'MMM d, y';
        }
        return _super.prototype.transform.call(this, value, args);
    };
    return MyDatePipe;
}(DatePipe));
export { MyDatePipe };
