import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseEditComponent } from '../../components/base-edit-component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { HolidayService } from '../holiday.service';
var HolidayComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HolidayComponent, _super);
    function HolidayComponent(holidayService, route, location, snackBar, dialog) {
        var _this = _super.call(this, dialog) || this;
        _this.holidayService = holidayService;
        _this.route = route;
        _this.location = location;
        _this.snackBar = snackBar;
        return _this;
    }
    HolidayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (value) {
            _this.holiday = value.holiday;
            _this.isNew = !_this.holiday.id;
        });
    };
    HolidayComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.holidayService.saveHoliday(this.holiday).toPromise()];
                    case 1:
                        _a.holiday = _b.sent();
                        this.location.back();
                        this.snackBar.open(this.holiday.name + " " + (this.isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    HolidayComponent.prototype.deleteHoliday = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, 'Delete Holiday?', 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.holidayService.deleteHoliday(this.holiday.id).toPromise()];
                    case 2:
                        _a.sent();
                        this.location.back();
                        this.snackBar.open(this.holiday.name + " Deleted", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    return HolidayComponent;
}(BaseEditComponent));
export { HolidayComponent };
