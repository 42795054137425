import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseEditComponent } from '../../components/base-edit-component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndorsementService } from '../endorsement.service';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
var EndorsementComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EndorsementComponent, _super);
    function EndorsementComponent(endorsementService, route, location, snackBar, dialog) {
        var _this = _super.call(this, dialog) || this;
        _this.endorsementService = endorsementService;
        _this.route = route;
        _this.location = location;
        _this.snackBar = snackBar;
        _this.route.data.subscribe(function (value) {
            _this.endorsement = value.endorsement;
            _this.isNew = !_this.endorsement.id;
        });
        return _this;
    }
    EndorsementComponent.prototype.ngOnInit = function () {
    };
    EndorsementComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.endorsementService.saveEndorsement(this.endorsement).toPromise()];
                    case 1:
                        _a.endorsement = _b.sent();
                        this.location.back();
                        this.snackBar.open(this.endorsement.name + " " + (this.isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    EndorsementComponent.prototype.deleteEndorsement = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, 'Delete Certification?', 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.endorsementService.deleteEndorsement(this.endorsement.id).toPromise()];
                    case 2:
                        _a.sent();
                        this.location.back();
                        this.snackBar.open(this.endorsement.name + " Deleted", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    return EndorsementComponent;
}(BaseEditComponent));
export { EndorsementComponent };
