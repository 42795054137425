import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from '../group.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../dialog/confirm-dialog/confirm-dialog.component';
import { BaseEditComponent } from '../../../components/base-edit-component';
import { Location } from '@angular/common';
var GroupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(GroupComponent, _super);
    function GroupComponent(route, router, groupService, snackBar, location, dialog) {
        var _this = _super.call(this, dialog) || this;
        _this.route = route;
        _this.router = router;
        _this.groupService = groupService;
        _this.snackBar = snackBar;
        _this.location = location;
        _this.isNew = false;
        _this.disabledDueToDuplicateSupervisor = false;
        return _this;
    }
    GroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (value) {
            _this.group = value.group;
            _this.isNew = !_this.group.id;
            _this.groups = value.groups;
            if (!_this.isNew)
                _this.groups = _this.groups.filter(function (g) { return g.id != _this.group.id; });
            _this.people = value.staff;
            _this.children = _this.isNew ? [] : _this.groups.filter(function (group) { return group.parentId === _this.group.id; });
            setTimeout(function () { return _this.supervisorChanged(_this.group.supervisor, true); });
        });
    };
    GroupComponent.prototype.supervisorChanged = function (supervisorId, initial) {
        var _this = this;
        if (initial === void 0) { initial = false; }
        this.group.supervisor = supervisorId;
        if (!initial && !this.group.supervisor) {
            this.group.approverIsSupervisor = false;
            return;
        }
        var orgs = this.findOrgsSupervisorIsOver(this.group.supervisor);
        //the current group is not included
        //so if it's more than zero including this one then it's duplicated
        if (orgs.length > 0) {
            var groupNames = orgs.map(function (value) { return value.groupName; })
                .join(', ');
            this.snackBar.open("This Supervisor is already over department" + (orgs.length > 1 ? 's' : '') + ": [" + groupNames + "]," +
                " they will not be able to view details for other departments, all their departments should be grouped under one", 'Dismiss');
            this.refreshOrgChain();
            this.disabledDueToDuplicateSupervisor = true;
            return;
        }
        this.disabledDueToDuplicateSupervisor = false;
        var supervisor = this.people.find(function (p) { return p.id == _this.group.supervisor; });
        if (supervisor != null && supervisor.staff.email == null) {
            var editSuperSnackbar = this.snackBar.open("Supervisor won't get leave requests, they don't have a staff email", 'Edit Supervisor', { duration: 20000 });
            editSuperSnackbar.onAction().subscribe(function (value) {
                _this.save(['people', 'edit', supervisor.id]);
            });
        }
        this.refreshOrgChain();
    };
    GroupComponent.prototype.findOrgsSupervisorIsOver = function (personId) {
        if (!personId)
            return [];
        return this.groups.filter(function (value) { return value.supervisor == personId; });
    };
    GroupComponent.prototype.refreshOrgChain = function () {
        this.orgChain = this.groupService.buildOrgChain(this.group, this.people, this.groups, this.group.supervisor);
    };
    GroupComponent.prototype.save = function (navigateTo) {
        if (navigateTo === void 0) { navigateTo = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.groupService.updateGroup(this.group)];
                    case 1:
                        _a.sent();
                        if (navigateTo)
                            this.router.navigate(navigateTo);
                        else
                            this.location.back();
                        this.snackBar.open(this.group.groupName + " " + (this.isNew ? 'Added' : 'Saved'), null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    GroupComponent.prototype.deleteGroup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete " + this.group.type + " " + this.group.groupName, 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.groupService.deleteGroup(this.group.id)];
                    case 2:
                        _a.sent();
                        this.snackBar.open(this.group.type + " " + this.group.groupName + " deleted", null, { duration: 2000 });
                        this.router.navigate(['/groups/list']);
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupComponent;
}(BaseEditComponent));
export { GroupComponent };
