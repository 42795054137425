import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from './jwt-helper.service';
import { filter, map } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { CookieService } from 'ngx-cookie-service';
import { UserToken } from '../../login/user-token';
var LoginService = /** @class */ (function () {
    function LoginService(router, cookieService) {
        var _this = this;
        this.router = router;
        this.cookieService = cookieService;
        this.currentUserTokenSubject = new BehaviorSubject(null);
        this.redirectTo = router.routerState.snapshot.url;
        this.setLoggedIn(cookieService.get('.JwtAccessToken'));
        this.currentUserToken().subscribe(function (user) {
            if (user) {
                Sentry.setUser({ username: user.userName, id: user.personId, email: user.email });
                var email = user.email;
                if (email && user.oauth) {
                    _this.cookieService.set('.Sub', email);
                }
            }
            else {
                Sentry.setUser(null);
            }
        });
        // this.currentUserToken().subscribe(user => {
        //   if ()
        //     this.promptLogin();
        // })
    }
    Object.defineProperty(LoginService.prototype, "userToken", {
        get: function () {
            return new UserToken(JwtHelperService.decodeToken(this.currentUserTokenSubject.value));
        },
        enumerable: true,
        configurable: true
    });
    LoginService.prototype.promptLogin = function (redirectTo) {
        this.redirectTo = redirectTo || this.router.routerState.snapshot.url;
        this.router.navigate(['/login']);
    };
    LoginService.prototype.setLoggedIn = function (accessToken) {
        this.currentUserTokenSubject.next(accessToken);
    };
    LoginService.prototype.loggedIn = function () {
        return this.currentUserToken().pipe(map(function (user) { return user && user.expirationDate.valueOf() > new Date().valueOf(); }));
    };
    LoginService.prototype.currentUserToken = function () {
        return this.currentUserTokenSubject.pipe(map(function (jwt) { return jwt && new UserToken(JwtHelperService.decodeToken(jwt)); }));
    };
    LoginService.prototype.safeUserToken = function () {
        return this.currentUserToken().pipe(filter(function (value) { return !!value; }));
    };
    LoginService.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.loggedIn().pipe(map(function (loggedIn) {
            if (!loggedIn) {
                _this.promptLogin(state.url);
            }
            return loggedIn;
        }));
    };
    LoginService.prototype.isHrOrAdmin = function () {
        return this.hasAnyRole(['admin', 'hr']);
    };
    LoginService.prototype.hasAnyRole = function (roles) {
        return this.currentUserToken().pipe(map(function (user) { return user && roles.some(function (role) { return user.roles.includes(role); }); }));
    };
    LoginService.prototype.hasRole = function (role) {
        return this.currentUserToken().pipe(map(function (user) { return user && user.roles.includes(role); }));
    };
    return LoginService;
}());
export { LoginService };
