import * as tslib_1 from "tslib";
import { MatTableDataSource } from '@angular/material/table';
var AppDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(AppDataSource, _super);
    function AppDataSource() {
        var _this = _super.call(this) || this;
        _this.customColumnAccessors = {};
        _this._customFilter = function (data) { return true; };
        var ogSort = _this.sortingDataAccessor;
        _this.sortingDataAccessor = function (data, sortHeaderId) {
            if (_this.customColumnAccessors[sortHeaderId])
                return _this.customColumnAccessors[sortHeaderId](data);
            var index = 0;
            var paramIndex = sortHeaderId.indexOf('.', index);
            while (paramIndex > -1) {
                data = data[sortHeaderId.substring(index, paramIndex)];
                index = paramIndex + 1;
                paramIndex = sortHeaderId.indexOf('.', index);
                if (data === null || data === undefined)
                    return null;
            }
            if (index > 0) {
                sortHeaderId = sortHeaderId.substring(index);
            }
            return ogSort(data, sortHeaderId);
        };
        return _this;
    }
    AppDataSource.prototype.customColumnAccessor = function (columnId, accessor) {
        this.customColumnAccessors[columnId] = accessor;
    };
    AppDataSource.prototype.observe = function (observable) {
        var _this = this;
        return observable.subscribe(function (value) {
            _this.unfilteredData = value;
            _this.filterUpdated();
        });
    };
    AppDataSource.prototype.bindToRouteData = function (route, dataName) {
        var _this = this;
        route.data.subscribe(function (value) {
            _this.unfilteredData = value[dataName];
            if (!_this.unfilteredData)
                throw new Error('unable to find data for table using property ' + dataName);
            _this.filterUpdated();
        });
    };
    Object.defineProperty(AppDataSource.prototype, "customFilter", {
        set: function (value) {
            this._customFilter = value;
        },
        enumerable: true,
        configurable: true
    });
    AppDataSource.prototype.filterUpdated = function () {
        var _this = this;
        this.data = this.unfilteredData.filter(function (value) { return _this._customFilter(value); });
    };
    return AppDataSource;
}(MatTableDataSource));
export { AppDataSource };
