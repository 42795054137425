import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EndorsementService = /** @class */ (function () {
    function EndorsementService(http) {
        this.http = http;
    }
    EndorsementService.prototype.list = function () {
        return this.http.get('/api/endorsement');
    };
    EndorsementService.prototype.getEndorsementById = function (id) {
        return this.http.get('/api/endorsement/' + id);
    };
    EndorsementService.prototype.saveEndorsement = function (endorsement) {
        return this.http.post('/api/endorsement', endorsement);
    };
    EndorsementService.prototype.deleteEndorsement = function (id) {
        return this.http.delete('/api/endorsement/' + id, { responseType: 'text' });
    };
    EndorsementService.prototype.saveRequiredEndorsement = function (requiredEndorsement) {
        return this.http.post('/api/endorsement/required', requiredEndorsement).toPromise();
    };
    EndorsementService.prototype.deleteRequiredEndorsement = function (id) {
        return this.http.delete('/api/endorsement/required/' + id, { responseType: 'text' }).toPromise();
    };
    EndorsementService.prototype.saveStaffEndorsement = function (staffEndorsement) {
        return this.http.post('/api/endorsement/staff', staffEndorsement).toPromise();
    };
    EndorsementService.prototype.deleteStaffEndorsement = function (id) {
        return this.http.delete('/api/endorsement/staff/' + id, { responseType: 'text' }).toPromise();
    };
    EndorsementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EndorsementService_Factory() { return new EndorsementService(i0.ɵɵinject(i1.HttpClient)); }, token: EndorsementService, providedIn: "root" });
    return EndorsementService;
}());
export { EndorsementService };
