import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/auth/login.service';
import { AuthenticateService } from '../services/auth/authenticate.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authenticateService, loginService, router, route, snackBar, document) {
        this.authenticateService = authenticateService;
        this.loginService = loginService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.document = document;
        this.passwordReset = false;
    }
    Object.defineProperty(LoginComponent.prototype, "redirectUrl", {
        get: function () {
            return this.route.snapshot.queryParams['redirect'] || this.loginService.redirectTo;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.login = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, errorResponse_1, success;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMessage = '';
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authenticateService.login(this.username, this.password, this.passwordReset ? this.newPassword : null)];
                    case 2:
                        user = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        errorResponse_1 = _a.sent();
                        this.errorMessage = errorResponse_1.error.message;
                        return [2 /*return*/];
                    case 4:
                        if (!user.resetPassword) return [3 /*break*/, 5];
                        this.passwordReset = true;
                        this.snackBar.open('Password reset required', 'Dissmiss', { duration: 2000 });
                        return [2 /*return*/];
                    case 5:
                        if (!this.route.snapshot.queryParamMap.has('redirect')) return [3 /*break*/, 6];
                        this.document.location.href = this.route.snapshot.queryParamMap.get('redirect');
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, this.router.navigate([this.loginService.redirectTo])];
                    case 7:
                        success = _a.sent();
                        if (!success)
                            this.router.navigate(['home']);
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
