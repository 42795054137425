import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './user/admin/admin.component';
import { UserResolveService } from './user/user-resolve.service';
import { HomeComponent } from './home/home.component';
import { IsNewResolverService } from './user/is-new-resolver.service';
import { MessageComponent } from './message/message.component';
import { IsSelfResolverService } from './user/is-self-resolver.service';
import { LoginService } from './services/auth/login.service';
import { PeopleResolveService } from './people/list/people-resolve.service';
import { GroupComponent } from './people/groups/group/group.component';
import { GroupResolveService } from './people/groups/group/group-resolve.service';
import { OrgGroupListComponent } from './people/groups/org-group-list/org-group-list.component';
import { GroupsResolveService } from './people/groups/org-group-list/groups-resolve.service';
import { LeaveRequestComponent } from './people/leave-request/leave-request.component';
import { LeaveListComponent } from './people/leave-request/leave-list/leave-list.component';
import { LeaveListResolverService } from './people/leave-request/leave-list/leave-list-resolver.service';
import { TrainingListComponent } from './people/training-requirement/training-list/training-list.component';
import { TrainingListResolverService } from './people/training-requirement/training-list-resolver.service';
import { TrainingEditComponent } from './people/training-requirement/training-edit/training-edit.component';
import { TrainingResolverService } from './people/training-requirement/training-resolver.service';
import { TrainingReportComponent } from './people/training-requirement/training-report/training-report.component';
import { StaffTrainingResolverService } from './people/training-requirement/staff-training-resolver.service';
import { StaffResolveService } from './people/staff-resolve.service';
import { LeaveRequestResolverService } from './people/leave-request/leave-request-resolver.service';
import { PeopleWithLeaveResolverService } from './people/leave-request/people-with-leave-resolver.service';
import { LeaveReportComponent } from './people/leave-request/leave-report/leave-report.component';
import { MissionOrgListComponent } from './mission-org/list/mission-org-list.component';
import { MissionOrgResolverService } from './mission-org/edit/mission-org-resolver.service';
import { MissionOrgListResolverService } from './mission-org/list/mission-org-list-resolver.service';
import { MissionOrgComponent } from './mission-org/edit/mission-org.component';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { SandboxComponent } from './components/sandbox/sandbox.component';
import { PersonRequiredGuard } from './services/person-required.guard';
import { OrgTreeComponent } from './org-tree/org-tree.component';
import { OrgTreeDataResolverService } from './org-tree/org-tree-data-resolver.service';
import { EndorsementListComponent } from './endorsement/list/endorsement-list.component';
import { EndorsementListResolverService } from './endorsement/endorsement-list-resolver.service';
import { EndorsementComponent } from './endorsement/edit/endorsement.component';
import { EndorsementResolverService } from './endorsement/endorsement-resolver.service';
import { PolicyGuard } from './services/auth/policy.guard';
import { CalendarComponent } from './calendar/calendar.component';
import { HolidayListComponent } from './holiday/list/holiday-list.component';
import { HolidayListResolverService } from './holiday/holiday-list-resolver.service';
import { HolidayResolverService } from './holiday/holiday-resolver.service';
import { HolidayComponent } from './holiday/edit/holiday.component';
var ɵ0 = {
    requirePolicy: 'userManager'
}, ɵ1 = function () { return import("./people/people.module.ngfactory").then(function (m) { return m.PeopleModuleNgFactory; }); }, ɵ2 = function () { return import("./job/job.module.ngfactory").then(function (m) { return m.JobModuleNgFactory; }); }, ɵ3 = {
    requirePolicy: 'orgGroupManager'
}, ɵ4 = {
    mine: true
}, ɵ5 = {
    requirePolicy: 'leaveSupervisor',
    supervisor: true
}, ɵ6 = {
    requirePolicy: 'leaveSupervisor',
    supervisor: true
}, ɵ7 = {
    requirePolicy: 'leaveManager'
}, ɵ8 = {
    all: true
}, ɵ9 = {
    all: true
}, ɵ10 = {
    mine: true
}, ɵ11 = {
    supervisor: true
}, ɵ12 = {
    requirePolicy: 'staffLeaveManager'
}, ɵ13 = {
    all: true,
    requirePolicy: 'leaveManager'
}, ɵ14 = {
    requirePolicy: 'hrEdit'
}, ɵ15 = {
    requirePolicy: 'hrEdit'
}, ɵ16 = {
    requirePolicy: 'missionOrgManager'
}, ɵ17 = {
    requirePolicy: 'orgChart'
}, ɵ18 = {
    requirePolicy: 'endorsementManager'
}, ɵ19 = {
    requirePolicy: 'leaveSupervisor',
    supervisor: true
}, ɵ20 = {
    mine: true
}, ɵ21 = {
    public: true
}, ɵ22 = {
    requirePolicy: 'leaveManager'
}, ɵ23 = {
    all: true
};
var routes = [
    {
        path: '',
        canActivate: [LoginService],
        children: [
            {
                path: 'user',
                children: [
                    {
                        path: 'admin',
                        component: AdminComponent,
                        canActivate: [PolicyGuard],
                        data: ɵ0
                    },
                    {
                        path: 'edit/:name',
                        component: UserComponent,
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            user: UserResolveService,
                            isNew: IsNewResolverService,
                            isSelf: IsSelfResolverService
                        }
                    }
                ]
            },
            {
                path: 'people',
                loadChildren: ɵ1,
            },
            {
                path: 'job',
                loadChildren: ɵ2
            },
            {
                path: 'groups',
                canActivate: [PolicyGuard],
                data: ɵ3,
                children: [
                    {
                        path: 'edit/:id',
                        component: GroupComponent,
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            group: GroupResolveService,
                            groups: GroupsResolveService,
                            staff: StaffResolveService
                        }
                    },
                    {
                        path: 'list',
                        component: OrgGroupListComponent,
                        resolve: {
                            groups: GroupsResolveService
                        }
                    }
                ]
            },
            {
                path: 'leave-request',
                children: [
                    {
                        path: 'edit/:id',
                        component: LeaveRequestComponent,
                        canActivate: [PersonRequiredGuard],
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            leaveRequest: LeaveRequestResolverService,
                            people: PeopleWithLeaveResolverService
                        },
                        data: ɵ4
                    },
                    {
                        path: 'report',
                        children: [
                            {
                                path: 'supervisor',
                                canActivate: [PolicyGuard],
                                data: ɵ5,
                                component: LeaveReportComponent,
                                resolve: {
                                    people: PeopleWithLeaveResolverService
                                }
                            },
                            {
                                path: 'supervisor/:year',
                                canActivate: [PolicyGuard],
                                data: ɵ6,
                                component: LeaveReportComponent,
                                resolve: {
                                    people: PeopleWithLeaveResolverService
                                }
                            },
                            {
                                path: '',
                                canActivate: [PolicyGuard],
                                data: ɵ7,
                                children: [
                                    {
                                        path: 'all/:year',
                                        component: LeaveReportComponent,
                                        resolve: {
                                            people: PeopleWithLeaveResolverService,
                                        },
                                        data: ɵ8
                                    },
                                    {
                                        path: 'all',
                                        component: LeaveReportComponent,
                                        resolve: {
                                            people: PeopleWithLeaveResolverService,
                                        },
                                        data: ɵ9
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        path: 'list',
                        children: [
                            {
                                path: 'mine',
                                canActivate: [PersonRequiredGuard],
                                component: LeaveListComponent,
                                resolve: {
                                    leave: LeaveListResolverService
                                },
                                data: ɵ10
                            },
                            {
                                path: 'supervisor',
                                component: LeaveListComponent,
                                resolve: {
                                    leave: LeaveListResolverService
                                },
                                data: ɵ11
                            },
                            {
                                path: '',
                                canActivate: [PolicyGuard],
                                data: ɵ12,
                                children: [
                                    {
                                        path: 'supervisor/:supervisorId',
                                        component: LeaveListComponent,
                                        resolve: {
                                            leave: LeaveListResolverService
                                        }
                                    },
                                    {
                                        path: 'all',
                                        component: LeaveListComponent,
                                        resolve: {
                                            leave: LeaveListResolverService
                                        },
                                        data: ɵ13
                                    },
                                    {
                                        path: ':personId',
                                        component: LeaveListComponent,
                                        resolve: {
                                            leave: LeaveListResolverService
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                path: 'holiday',
                canActivate: [PolicyGuard],
                data: ɵ14,
                children: [
                    {
                        path: 'list',
                        component: HolidayListComponent,
                        resolve: {
                            holidays: HolidayListResolverService
                        }
                    },
                    {
                        path: 'edit/:id',
                        component: HolidayComponent,
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            holiday: HolidayResolverService
                        }
                    }
                ]
            },
            {
                path: 'training',
                canActivate: [PolicyGuard],
                data: ɵ15,
                children: [
                    {
                        path: 'list',
                        component: TrainingListComponent,
                        resolve: {
                            trainingRequirements: TrainingListResolverService
                        }
                    },
                    {
                        path: 'edit/:id',
                        component: TrainingEditComponent,
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            training: TrainingResolverService,
                            people: PeopleResolveService,
                            groups: GroupsResolveService,
                        }
                    },
                    {
                        path: 'report/:year',
                        component: TrainingReportComponent,
                        resolve: {
                            staffTraining: StaffTrainingResolverService,
                        }
                    },
                    {
                        path: 'report',
                        component: TrainingReportComponent,
                        resolve: {
                            staffTraining: StaffTrainingResolverService,
                        }
                    }
                ]
            },
            {
                path: 'mission-org',
                canActivate: [PolicyGuard],
                data: ɵ16,
                children: [
                    {
                        path: 'list',
                        component: MissionOrgListComponent,
                        resolve: {
                            missionOrgs: MissionOrgListResolverService
                        }
                    },
                    {
                        path: 'edit/:id',
                        component: MissionOrgComponent,
                        canDeactivate: [CanDeactivateGuard],
                        resolve: {
                            missionOrg: MissionOrgResolverService,
                            people: PeopleResolveService
                        }
                    }
                ]
            },
            {
                path: 'org-tree',
                canActivate: [PolicyGuard],
                data: ɵ17,
                children: [
                    {
                        path: 'all',
                        component: OrgTreeComponent,
                        resolve: {
                            treeData: OrgTreeDataResolverService
                        }
                    },
                    {
                        path: ':rootId',
                        component: OrgTreeComponent,
                        resolve: {
                            treeData: OrgTreeDataResolverService
                        }
                    }
                ]
            },
            {
                path: 'endorsement',
                canActivate: [PolicyGuard],
                data: ɵ18,
                children: [
                    {
                        path: 'list',
                        component: EndorsementListComponent,
                        resolve: {
                            endorsements: EndorsementListResolverService
                        }
                    },
                    {
                        path: 'edit/:id',
                        component: EndorsementComponent,
                        resolve: {
                            endorsement: EndorsementResolverService
                        }
                    }
                ]
            },
            {
                path: 'calendar',
                children: [
                    {
                        path: 'supervisor',
                        component: CalendarComponent,
                        resolve: {
                            leave: LeaveListResolverService
                        },
                        canActivate: [PolicyGuard],
                        data: ɵ19
                    },
                    {
                        path: 'mine',
                        component: CalendarComponent,
                        resolve: {
                            leave: LeaveListResolverService
                        },
                        data: ɵ20
                    },
                    {
                        path: 'public',
                        component: CalendarComponent,
                        resolve: {
                            leave: LeaveListResolverService
                        },
                        data: ɵ21
                    },
                    {
                        path: '',
                        canActivate: [PolicyGuard],
                        data: ɵ22,
                        children: [
                            {
                                path: 'supervisor/:supervisorId',
                                component: CalendarComponent,
                                resolve: {
                                    leave: LeaveListResolverService
                                }
                            },
                            {
                                path: 'all',
                                component: CalendarComponent,
                                resolve: {
                                    leave: LeaveListResolverService
                                },
                                data: ɵ23
                            },
                            {
                                path: ':personId',
                                component: CalendarComponent,
                                resolve: {
                                    leave: LeaveListResolverService
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'sandbox',
                component: SandboxComponent
            },
            {
                path: 'home',
                component: HomeComponent
            },
            {
                path: '',
                redirectTo: '/home',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'message',
        component: MessageComponent
    },
    {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23 };
