import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
var MissionOrg = /** @class */ (function (_super) {
    tslib_1.__extends(MissionOrg, _super);
    function MissionOrg() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MissionOrg;
}(BaseEntity));
export { MissionOrg };
var MissionOrgWithNames = /** @class */ (function (_super) {
    tslib_1.__extends(MissionOrgWithNames, _super);
    function MissionOrgWithNames() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MissionOrgWithNames;
}(MissionOrg));
export { MissionOrgWithNames };
var MissionOrgWithYearSummaries = /** @class */ (function (_super) {
    tslib_1.__extends(MissionOrgWithYearSummaries, _super);
    function MissionOrgWithYearSummaries() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.yearSummaries = [];
        return _this;
    }
    return MissionOrgWithYearSummaries;
}(MissionOrg));
export { MissionOrgWithYearSummaries };
export var MissionOrgStatus;
(function (MissionOrgStatus) {
    MissionOrgStatus["Associate"] = "Associate";
    MissionOrgStatus["OwnerAssociate"] = "OwnerAssociate";
    MissionOrgStatus["FoundingAssociate"] = "FoundingAssociate";
    MissionOrgStatus["Founder"] = "Founder";
})(MissionOrgStatus || (MissionOrgStatus = {}));
