import * as tslib_1 from "tslib";
import { EmbeddedViewRef, TemplateRef } from '@angular/core';
import { AccordionListComponent } from './accordion-list.component';
var AccordionListContentDirective = /** @class */ (function (_super) {
    tslib_1.__extends(AccordionListContentDirective, _super);
    function AccordionListContentDirective(parent, templateRef) {
        var _this = _super.call(this) || this;
        _this.templateRef = templateRef;
        parent.content = _this;
        return _this;
    }
    AccordionListContentDirective.prototype.createEmbeddedView = function (context) {
        return this.templateRef.createEmbeddedView(context);
    };
    Object.defineProperty(AccordionListContentDirective.prototype, "elementRef", {
        get: function () {
            return this.templateRef.elementRef;
        },
        enumerable: true,
        configurable: true
    });
    return AccordionListContentDirective;
}(TemplateRef));
export { AccordionListContentDirective };
