import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { PersonService } from '../people/person.service';
import { environment } from '../../environments/environment';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { BaseEditComponent } from '../components/base-edit-component';
import { Location } from '@angular/common';
var UserComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserComponent, _super);
    function UserComponent(route, userService, authService, router, location, dialog, personService, snackBar) {
        var _this = _super.call(this, dialog) || this;
        _this.route = route;
        _this.userService = userService;
        _this.authService = authService;
        _this.router = router;
        _this.location = location;
        _this.personService = personService;
        _this.snackBar = snackBar;
        _this.isDev = !environment.production;
        _this.people = _this.personService.getAll();
        return _this;
    }
    UserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.user = data.user;
            _this.isNew = data.isNew;
            _this.isSelf = data.isSelf;
        });
    };
    UserComponent.prototype.saveUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userService.saveUser(this.user, this.password, this.isNew, this.isSelf)];
                    case 1:
                        _a.sent();
                        this.router.navigate([this.isSelf ? '/home' : '/user/admin']);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.updateRoles = function (roles) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, roles_1, newRole, existingRoles, _a, existingRoles_1, oldRole;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (roles.includes('hradmin') && !roles.includes('hr'))
                            roles.push('hr');
                        _i = 0, roles_1 = roles;
                        _b.label = 1;
                    case 1:
                        if (!(_i < roles_1.length)) return [3 /*break*/, 4];
                        newRole = roles_1[_i];
                        if (this.user.roles.includes(newRole))
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.grantRole(newRole)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        existingRoles = this.user.roles;
                        _a = 0, existingRoles_1 = existingRoles;
                        _b.label = 5;
                    case 5:
                        if (!(_a < existingRoles_1.length)) return [3 /*break*/, 8];
                        oldRole = existingRoles_1[_a];
                        if (roles.includes(oldRole))
                            return [3 /*break*/, 7];
                        return [4 /*yield*/, this.revokeRole(oldRole)];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7:
                        _a++;
                        return [3 /*break*/, 5];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.grantRole = function (role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userService.grantRole(role, this.user.id)];
                    case 1:
                        _a.sent();
                        this.user.roles = this.user.roles.concat([role]);
                        this.snackBar.open("Role granted, user must logout and login to use new role", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.revokeRole = function (role) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userService.revokeRole(role, this.user.id)];
                    case 1:
                        _a.sent();
                        this.user.roles = this.user.roles.filter(function (value) { return value != role; });
                        this.snackBar.open("Role revoked, user must logout and login to lose the role", null, { duration: 2000 });
                        return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.deleteUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConfirmDialogComponent.OpenWait(this.dialog, "Delete User " + this.user.userName + "?", 'Delete', 'Cancel')];
                    case 1:
                        result = _a.sent();
                        if (!result) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.userService.deleteUser(this.user.id)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['/user/admin']);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.impersonate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.impersonate(this.user.email)];
                    case 1:
                        _a.sent();
                        this.snackBar.open("You're now logged in as " + this.user.userName + " just logout to stop impersonating them", null, { duration: 2000 });
                        return [4 /*yield*/, this.router.navigate(['/home'])];
                    case 2:
                        _a.sent();
                        this.location.go(this.location.path());
                        return [2 /*return*/];
                }
            });
        });
    };
    return UserComponent;
}(BaseEditComponent));
export { UserComponent };
