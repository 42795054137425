import * as tslib_1 from "tslib";
import * as Sentry from '@sentry/browser';
var SettingsService = /** @class */ (function () {
    function SettingsService() {
    }
    //this is called by the settingsServiceFunction
    SettingsService.prototype.setSettings = function (settings) {
        var _this = this;
        this.settings = settings;
        Sentry.addGlobalEventProcessor(function (event, hint) {
            event.release = _this.get('version');
            return event;
        });
    };
    SettingsService.prototype.get = function (name, defaultValue) {
        return this.settings[name] || defaultValue;
    };
    return SettingsService;
}());
export { SettingsService };
export function configureSettings(http, settingsService) {
    var _this = this;
    return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () { var _a, _b; return tslib_1.__generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _b = (_a = settingsService).setSettings;
                return [4 /*yield*/, http.get('/api/settings').toPromise()];
            case 1: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
        }
    }); }); };
}
