import * as tslib_1 from "tslib";
import { EmbeddedViewRef, TemplateRef } from '@angular/core';
import { AccordionListComponent } from './accordion-list.component';
var AccordionListHeaderDirective = /** @class */ (function (_super) {
    tslib_1.__extends(AccordionListHeaderDirective, _super);
    function AccordionListHeaderDirective(parent, templateRef) {
        var _this = _super.call(this) || this;
        _this.templateRef = templateRef;
        parent.header = _this;
        return _this;
    }
    AccordionListHeaderDirective.prototype.createEmbeddedView = function (context) {
        return this.templateRef.createEmbeddedView(context);
    };
    Object.defineProperty(AccordionListHeaderDirective.prototype, "elementRef", {
        get: function () {
            return this.templateRef.elementRef;
        },
        enumerable: true,
        configurable: true
    });
    return AccordionListHeaderDirective;
}(TemplateRef));
export { AccordionListHeaderDirective };
