import { JobService } from '../job/job.service';
import * as i0 from "@angular/core";
import * as i1 from "../job/job.service";
var AllRolesResolverService = /** @class */ (function () {
    function AllRolesResolverService(jobService) {
        this.jobService = jobService;
    }
    AllRolesResolverService.prototype.resolve = function (route, state) {
        return this.jobService.getAllRoles();
    };
    AllRolesResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AllRolesResolverService_Factory() { return new AllRolesResolverService(i0.ɵɵinject(i1.JobService)); }, token: AllRolesResolverService, providedIn: "root" });
    return AllRolesResolverService;
}());
export { AllRolesResolverService };
