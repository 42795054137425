import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaffTraining } from '../staff-training';
import { TrainingRequirementService } from '../training-requirement.service';
import { Year } from '../year';
import { BehaviorSubject } from 'rxjs';
import { map, pluck, share } from 'rxjs/operators';
import { PersonService } from '../../person.service';
import * as moment from 'moment';
import { GroupService } from '../../groups/group.service';
var TrainingReportComponent = /** @class */ (function () {
    function TrainingReportComponent(route, trainingService, personService, orgGroupService, router) {
        var _this = this;
        this.route = route;
        this.trainingService = trainingService;
        this.personService = personService;
        this.orgGroupService = orgGroupService;
        this.router = router;
        this.completedDate = moment();
        this.staffTraining = new BehaviorSubject(null);
        this.showCompleted = new BehaviorSubject(true);
        this.years = Year.years();
        this.selectedYearSubject = new BehaviorSubject(null);
        this.route.params.pipe(pluck('year'), map(function (value) { return value || Year.CurrentSchoolYear(); }), map(function (yearValue) { return _this.years.find(function (year) { return year.value == yearValue; }); })).subscribe(this.selectedYearSubject);
        this.selectedYearSubject.subscribe(function (year) {
            _this.selectedYear = year;
            _this.completedDate = year.convertToSchoolYear(_this.completedDate);
        });
        this.route.queryParamMap.pipe(map(function (params) {
            return params.has('showCompleted') ? params.get('showCompleted') == 'true' : true;
        })).subscribe(this.showCompleted);
        var groups = this.orgGroupService.getAll().pipe(share());
        var staff = this.personService.getStaffWithRoles().pipe(share());
        var trainingRequirements = this.trainingService.list().pipe(share());
        this.route.data.pipe(pluck('staffTraining')).subscribe(this.staffTraining);
        this.requirementsWithStaff = this.trainingService.buildRequirementsWithStaff(staff, groups, trainingRequirements, this.staffTraining.asObservable(), this.selectedYearSubject.pipe(pluck('value')), this.showCompleted);
    }
    TrainingReportComponent.prototype.ngOnInit = function () {
    };
    TrainingReportComponent.prototype.setYear = function (year) {
        this.updateNavigation(year, this.showCompleted.getValue());
    };
    Object.defineProperty(TrainingReportComponent.prototype, "isLastYear", {
        get: function () {
            return this.years[0].value === this.selectedYear.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrainingReportComponent.prototype, "isFirstYear", {
        get: function () {
            return this.years[this.years.length - 1].value === this.selectedYear.value;
        },
        enumerable: true,
        configurable: true
    });
    TrainingReportComponent.prototype.setShowCompleted = function (show) {
        this.updateNavigation(this.selectedYear.value, show);
    };
    TrainingReportComponent.prototype.updateNavigation = function (year, showCompleted) {
        this.router.navigate([this.route.snapshot.params['year'] ? '..' : '.', year], {
            relativeTo: this.route,
            queryParams: { showCompleted: showCompleted }
        });
    };
    TrainingReportComponent.prototype.completeTraining = function (reqObject, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var staffWithTraining, staffTraining;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staffWithTraining = reqObject.staffsWithTraining[index];
                        staffTraining = new StaffTraining();
                        staffTraining.trainingRequirementId = reqObject.requirement.id;
                        staffTraining.staffId = staffWithTraining.staff.id;
                        staffTraining.completedDate = this.completedDate.toDate();
                        return [4 /*yield*/, this.trainingService.saveStaffTraining(staffTraining)];
                    case 1:
                        _a.sent();
                        this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                            .subscribe(function (training) { return _this.staffTraining.next(training); });
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingReportComponent.prototype.markSelectedComplete = function (reqObject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var staffIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staffIds = reqObject.staffsWithTraining
                            .filter(function (value) { return !value.training.completedDate && value.selected; })
                            .map(function (value) { return value.staff.id; });
                        return [4 /*yield*/, this.trainingService.markAllComplete(staffIds, reqObject.requirement.id, this.completedDate.toDate())];
                    case 1:
                        _a.sent();
                        this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                            .subscribe(function (training) { return _this.staffTraining.next(training); });
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingReportComponent.prototype.markAllComplete = function (reqObject) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var staffIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        staffIds = reqObject.staffsWithTraining
                            .filter(function (value) { return !value.training.completedDate; })
                            .map(function (value) { return value.staff.id; });
                        return [4 /*yield*/, this.trainingService.markAllComplete(staffIds, reqObject.requirement.id, this.completedDate.toDate())];
                    case 1:
                        _a.sent();
                        this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                            .subscribe(function (training) { return _this.staffTraining.next(training); });
                        return [2 /*return*/];
                }
            });
        });
    };
    TrainingReportComponent.prototype.allSelected = function (reqObject) {
        var hasSelected = false;
        var hasNotSelected = false;
        for (var _i = 0, _a = reqObject.staffsWithTraining.filter(function (st) { return !st.training.completedDate; }); _i < _a.length; _i++) {
            var value = _a[_i];
            if (value.selected) {
                hasSelected = true;
            }
            else {
                hasNotSelected = true;
            }
            if (hasNotSelected && hasSelected)
                return null;
        }
        return hasSelected && !hasNotSelected;
    };
    TrainingReportComponent.prototype.selectAll = function (reqObject, selected) {
        for (var _i = 0, _a = reqObject.staffsWithTraining.filter(function (st) { return !st.training.completedDate; }); _i < _a.length; _i++) {
            var value = _a[_i];
            value.selected = selected;
        }
    };
    return TrainingReportComponent;
}());
export { TrainingReportComponent };
