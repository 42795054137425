import * as tslib_1 from "tslib";
import { BaseEntity } from '../classes/base-entity';
var Attachment = /** @class */ (function (_super) {
    tslib_1.__extends(Attachment, _super);
    function Attachment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Attachment;
}(BaseEntity));
export { Attachment };
