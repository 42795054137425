import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppTemplateService } from '../../toolbar/app-template.service';
var RenderTemplateBottomSheetComponent = /** @class */ (function () {
    function RenderTemplateBottomSheetComponent(data, sheetRef, appTemplateService) {
        this.sheetRef = sheetRef;
        this.templateObservable = appTemplateService.ObserveTemplateRef(data.template);
        this.context = tslib_1.__assign({ $implicit: sheetRef }, data.context);
    }
    RenderTemplateBottomSheetComponent.Open = function (bottomSheet, template, context) {
        if (context === void 0) { context = {}; }
        return bottomSheet.open(RenderTemplateBottomSheetComponent, {
            data: {
                template: template,
                context: context
            }
        });
    };
    RenderTemplateBottomSheetComponent.OpenWait = function (bottomSheet, template) {
        return this.Open(bottomSheet, template).afterDismissed().toPromise();
    };
    RenderTemplateBottomSheetComponent.prototype.ngOnInit = function () {
    };
    return RenderTemplateBottomSheetComponent;
}());
export { RenderTemplateBottomSheetComponent };
