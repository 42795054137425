import { OnDestroy, TemplateRef } from '@angular/core';
import { AppTemplateService } from '../toolbar/app-template.service';
var AppTemplateContentDirective = /** @class */ (function () {
    function AppTemplateContentDirective(templateRef, toolbarService) {
        this.templateRef = templateRef;
        this.toolbarService = toolbarService;
    }
    Object.defineProperty(AppTemplateContentDirective.prototype, "templateName", {
        set: function (value) {
            this._templateName = value;
            this.toolbarService.setTemplate(value, this.templateRef);
        },
        enumerable: true,
        configurable: true
    });
    AppTemplateContentDirective.prototype.ngOnDestroy = function () {
        this.toolbarService.setTemplate(this._templateName, null);
    };
    return AppTemplateContentDirective;
}());
export { AppTemplateContentDirective };
