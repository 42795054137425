import * as tslib_1 from "tslib";
import { JobStatus } from '../job/job';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
var OrgTree = /** @class */ (function () {
    function OrgTree(data, urlBinding, rootId) {
        var _this = this;
        this.supervisorCountById = {};
        this.data = data;
        this.urlBinding = urlBinding;
        this.nodes = data.groups.filter(function (org) { return org.parentId == null || org.id == rootId; })
            .map(function (org) { return _this.buildOrgNode(org, _this.data); });
        for (var _i = 0, _a = data.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            if (!group.supervisor)
                continue;
            this.supervisorCountById[group.supervisor] = (this.supervisorCountById[group.supervisor] || 0) + 1;
        }
    }
    OrgTree.prototype.buildJobNode = function (job, data) {
        var _this = this;
        return new JobOrgNode(job.id, job, 'job', data.roles
            .filter(function (role) { return role.jobId == job.id; })
            .map(function (role) { return _this.buildRoleNode(role, data); }), this.jobFilter());
    };
    OrgTree.prototype.jobFilter = function () {
        if (!this.urlBinding)
            return undefined;
        return combineLatest(this.urlBinding.observableValues.allRoles)
            .pipe(map(function (_a) {
            var allRoles = _a[0];
            return function (value) { return value.active || allRoles; };
        }));
    };
    OrgTree.prototype.buildRoleNode = function (role, data) {
        return new RoleOrgNode(role.personId, role, 'role', []);
    };
    OrgTree.prototype.buildOrgNode = function (org, data) {
        var _this = this;
        return new GroupOrgNode(org.id, org, 'org', data.jobs
            .filter(function (value) { return value.orgGroupId == org.id; })
            .map(function (value) { return _this.buildJobNode(value, data); }).concat(data.groups
            .filter(function (value) { return value.parentId == org.id; })
            .map(function (value) { return _this.buildOrgNode(value, data); })), this.orgFilter());
    };
    OrgTree.prototype.orgFilter = function () {
        if (!this.urlBinding)
            return undefined;
        return combineLatest(this.urlBinding.observableValues.allJobs, this.urlBinding.observableValues.show)
            .pipe(map(function (_a) {
            var allJobs = _a[0], show = _a[1];
            return function (child) {
                if ('title' in child) {
                    if (!allJobs && !child.current)
                        return false;
                    if (child.status == JobStatus.SchoolAid && !show.includes('aids'))
                        return false;
                    if (child.status != JobStatus.SchoolAid && !show.includes('staff'))
                        return false;
                }
                return true;
            };
        }));
    };
    return OrgTree;
}());
export { OrgTree };
var OrgTreeData = /** @class */ (function () {
    function OrgTreeData() {
    }
    return OrgTreeData;
}());
export { OrgTreeData };
var OrgNode = /** @class */ (function () {
    function OrgNode(id, value, type, children, observableFilter) {
        var _this = this;
        this.parent = null;
        this.id = id;
        this.value = value;
        this.type = type;
        this.allChildren = children;
        for (var _i = 0, _a = this.allChildren; _i < _a.length; _i++) {
            var child = _a[_i];
            child.parent = this;
        }
        this.observableChildren = new BehaviorSubject(this.allChildren);
        if (observableFilter)
            observableFilter.pipe(map(function (filter) { return _this.allChildren.filter(function (child) { return filter(child.value); }); }))
                .subscribe(this.observableChildren);
    }
    Object.defineProperty(OrgNode.prototype, "hasChildren", {
        get: function () {
            return this.filteredChildren.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "filteredChildren", {
        get: function () {
            return this.observableChildren.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "level", {
        get: function () {
            return !this.parent ? 0 : (this.parent.level + 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "openJobs", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "jobsAvalible", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "activeStaff", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "activeAids", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgNode.prototype, "allDecendantsGroups", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    return OrgNode;
}());
export { OrgNode };
var JobOrgNode = /** @class */ (function (_super) {
    tslib_1.__extends(JobOrgNode, _super);
    function JobOrgNode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(JobOrgNode.prototype, "openJobs", {
        get: function () {
            return this.jobsAvalible - (this.filteredChildren.filter(function (child) { return child.value.active; }).length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobOrgNode.prototype, "jobsAvalible", {
        get: function () {
            return this.value.positions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobOrgNode.prototype, "activeStaff", {
        get: function () {
            if (this.value.status == JobStatus.SchoolAid)
                return 0;
            return (this.filteredChildren.filter(function (child) { return child.value.active; }).length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobOrgNode.prototype, "activeAids", {
        get: function () {
            if (this.value.status != JobStatus.SchoolAid)
                return 0;
            return (this.filteredChildren.filter(function (child) { return child.value.active; }).length);
        },
        enumerable: true,
        configurable: true
    });
    return JobOrgNode;
}(OrgNode));
export { JobOrgNode };
var GroupOrgNode = /** @class */ (function (_super) {
    tslib_1.__extends(GroupOrgNode, _super);
    function GroupOrgNode() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._allDecendantsGroups = null;
        return _this;
    }
    Object.defineProperty(GroupOrgNode.prototype, "openJobs", {
        get: function () {
            return this.filteredChildren.reduce(function (previousValue, currentValue) { return previousValue + currentValue.openJobs; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupOrgNode.prototype, "jobsAvalible", {
        get: function () {
            return this.filteredChildren.reduce(function (previousValue, currentValue) { return previousValue + currentValue.jobsAvalible; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupOrgNode.prototype, "activeStaff", {
        get: function () {
            return this.filteredChildren.reduce(function (previousValue, currentValue) { return previousValue + currentValue.activeStaff; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupOrgNode.prototype, "activeAids", {
        get: function () {
            return this.filteredChildren.reduce(function (previousValue, currentValue) { return previousValue + currentValue.activeAids; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupOrgNode.prototype, "allDecendantsGroups", {
        get: function () {
            var _a;
            if (this._allDecendantsGroups == null) {
                this._allDecendantsGroups = (_a = [this.value.groupName]).concat.apply(_a, this.allChildren.map(function (value) { return value.allDecendantsGroups; }));
            }
            return this._allDecendantsGroups;
        },
        enumerable: true,
        configurable: true
    });
    return GroupOrgNode;
}(OrgNode));
export { GroupOrgNode };
var RoleOrgNode = /** @class */ (function (_super) {
    tslib_1.__extends(RoleOrgNode, _super);
    function RoleOrgNode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoleOrgNode;
}(OrgNode));
export { RoleOrgNode };
