import { configureSettings, SettingsService } from './services/settings.service';
var ɵ0 = function (settings) { return ({
    apiKey: settings.get('googleAPIKey'),
    client_id: settings.get('googleClientId'),
    scope: 'https://www.googleapis.com/auth/drive'
}); }, ɵ1 = configureSettings;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
