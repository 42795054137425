var DateModel = /** @class */ (function () {
    function DateModel(date, items, inMonth) {
        this.inMonth = inMonth;
        this.date = date;
        this.items = items;
    }
    Object.defineProperty(DateModel.prototype, "dayOfMonth", {
        get: function () {
            return this.date.date();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateModel.prototype, "monthName", {
        get: function () {
            return this.date.format('MMM');
        },
        enumerable: true,
        configurable: true
    });
    return DateModel;
}());
export { DateModel };
