import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { User } from '../../user/user';
import { CookieService } from 'ngx-cookie-service';
var AuthenticateService = /** @class */ (function () {
    function AuthenticateService(http, loginService, cookieService) {
        this.http = http;
        this.loginService = loginService;
        this.cookieService = cookieService;
    }
    AuthenticateService.prototype.login = function (username, currentPassword, newPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body, json;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = {
                            Username: username,
                            Password: currentPassword
                        };
                        if (newPassword)
                            body['NewPassword'] = newPassword;
                        return [4 /*yield*/, this.http.post("/api/authenticate/" + (newPassword ? 'reset' : 'signin'), body).toPromise()];
                    case 1:
                        json = _a.sent();
                        return [2 /*return*/, this.postLogin(json)];
                }
            });
        });
    };
    AuthenticateService.prototype.impersonate = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.postLogin;
                        return [4 /*yield*/, this.http.get('/api/authenticate/impersonate/' + encodeURI(email))
                                .toPromise()];
                    case 1:
                        user = _a.apply(this, [_b.sent()]);
                        this.cookieService.delete('.Sub');
                        return [2 /*return*/, user];
                }
            });
        });
    };
    AuthenticateService.prototype.postLogin = function (json) {
        var user = Object.assign(new User(), json.user);
        if (!user.resetPassword) {
            //we won't get an access token back if the users password needs to be reset
            this.loginService.setLoggedIn(json.access_token);
        }
        return user;
    };
    return AuthenticateService;
}());
export { AuthenticateService };
